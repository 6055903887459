import React from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import {
  BonusRewardListing as BsBonusRewardListing,
} from 'cb-design-system'
import { ReactReduxContext } from 'react-redux'
import { AuthenticationState, authProviderClient, AzureADClient } from '../../AuthComponent'

const BonusRewardsListing = (props) => {
  const model = props.fields

  return (
    <React.Fragment>
      <ReactReduxContext.Consumer>
        {((context) => {
          return (
            <AzureADClient provider={authProviderClient} reduxStore={context.store}>
              {
                ({ authenticationState, error }) => {
                  if (error != null) {
                    console.error(error)
                  }
                  switch (authenticationState) {
                    case AuthenticationState.Authenticated:

                      return (
                        <BsBonusRewardListing
                          store={context.store}
                          model={model}
                        />
                      )
                    default:
                      return (
                        <></>
                      )
                  }
                }
              }
            </AzureADClient>
          )
        }).bind(this)}
      </ReactReduxContext.Consumer>
    </React.Fragment>
  )
}

export default withSitecoreContext()(BonusRewardsListing)
