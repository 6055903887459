import React from 'react';
import { connect } from 'react-redux';
import { FilterYear, Wrapper, Container, Row, Col } from 'cb-design-system'
import { ducks } from "../../ducks";
const { article } = ducks;
const { operations } = article
const { setYear } = operations

const ArticleListFilter = (props) => {
  return (
    <FilterYear {...props} />
  );
};

const mapStateToProps = (state) => {
  return {
    filterYear : state.article.filterYear || undefined
  };
}

const mapDispatchToProps = (dispatch) => ({
  setYear: (year) => dispatch(setYear(year))
});


export default connect(mapStateToProps, mapDispatchToProps)(ArticleListFilter);


