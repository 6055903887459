import React from 'react';
import { OfflineOrders as OffOrders } from 'cb-design-system';
import { connect, ReactReduxContext } from 'react-redux';
import { ducks } from "../../ducks";


const { offline: offlineDuck } = ducks;
const { operations } = offlineDuck;

const OfflineOrders = (props) => {
            return (

                <OffOrders
                    {...props}
                />
            )
}

const mapStateToProps = (state) => {

    console.log(state);

    return {
        offlineOrders: state.offline.orders.items,
        totalCount: state.offline.orders.totalCount,
        pageCount: state.offline.orders.pageCount,
        pageSize: state.offline.orders.pageSize,
        startIndex: state.offline.orders.startIndex,
        loading: state.offline.isLoading
    };
}

const mapDispatchToProps = (dispatch) => ({
    getOfflineOrders: (pageIndex, status, pageSize, orderSort, dateSort, searchText, startDate, endDate) => (dispatch(operations.getOfflineOrders(pageIndex, status, pageSize, orderSort, dateSort, searchText, startDate, endDate))),
    setSelectedOrder: (selectedOrder) => (dispatch(operations.setSelectedOrder(selectedOrder)))
});

export default connect(mapStateToProps, mapDispatchToProps)(OfflineOrders);