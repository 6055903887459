import React from 'react';
import { connect } from 'react-redux';
import { FeaturedCardList } from 'cb-design-system';
import { ducks } from "../../ducks";
import { Preview } from "../EatMenuDetail";

const { menu: menuDuck, cart: cartDuck } = ducks;

const { operations } = menuDuck
const { operations: cartOperations } = cartDuck
const { getFeatures } = operations
const { postItem: duckPostCart } = cartOperations



const EatSelectedCards = (props) => {
    
  // TODO: Make an Web API Call to get featured cards data by passing storeId.
  // Web API is /sitecore/api/GetEatSelectedProducts/{storeId}?datasource={CE7637D5-F638-488C-9E5B-0BBDBB6EBF4C}   // Return all featured products from sitecore commerce by passing store and/or category
  // props.rendering.datasource
  return(
    <>
    <Preview />
    <FeaturedCardList {...props}/>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    features : state.menu.features,
    currentCategory: state.menu.currentCategory,
    location: state.location.location,
    noLocation: !(state.location?.location?.olo_unique_name || state.location?.location?.oloUniqueName)
  };
}

const mapDispatchToProps = (dispatch) => ({
  getFeatured: (newPage) => dispatch(getFeatures(newPage)),
  addToOrder: (postData) => dispatch(duckPostCart(postData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EatSelectedCards);
