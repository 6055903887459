import React from 'react';
import { connect, ReactReduxContext } from 'react-redux';
import { AzureADClient, authProviderClient, AuthenticationState } from '../../AuthComponent';

import { Account as AccountPage } from 'cb-design-system';

// JSS
import { Link } from '@sitecore-jss/sitecore-jss-react';


/**
 * Demonstrates usage of a Content List field type within JSS.
 * Content Lists are references to 0..n other content items.
 * In Sitecore terms, this maps by default to a Treelist field.
 */
const CustomerSettings = (props) => {
  // const {

  // } = props.fields;

  // const layout = {
  //   background: 'solid',
  //   topPadding: 'medium',
  //   bottomPadding: 'medium',
  // }

  const handleLogin = () => {
    return authProviderClient.login();
  }

  const redirect = () => {
    return (typeof window !== 'undefined') ? window.location = '/' : null
  }

  return (
    <ReactReduxContext.Consumer>
      {((context) => {
        return (
          <AzureADClient provider={authProviderClient} reduxStore={context.store}>
            {
              ({login, logout, authenticationState, error, accountInfo}) => {
                switch (authenticationState) {
                  case AuthenticationState.Authenticated:
                    return (
                      <AccountPage store={context.store} account={accountInfo} logout={logout} />
                    )
                  default:
                    return (
                      <>
                      {redirect()}
                      </>
                    )
                }
              }
            }

          </AzureADClient>
        )}
        ).bind(this)
      }
    </ReactReduxContext.Consumer>
  );
};

export default CustomerSettings;

