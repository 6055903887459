import React, { useEffect, useState } from 'react';
import { Carousel, Container, Row, Col, ProductCard, Heading } from 'cb-design-system';

const CommerceCards = (props) => {

  const { items } = props.fields;
  console.log('Commerce items: ', items, props);
  const {
    totalCardsInRow = {},
    cardDisplayType = {},
    commerceCardType = {},
    Background = {},
    Padding = {},
    ctaLink = {},
    linkType = {},
    title = {},
    titleTag = {},
    subtitle = {},
    text = {},
  } = props.fields.fields || {}

  const isLoading = props.isLoading
  const setModel = (listItem) => {
    return {
      image: listItem.fields.image,
      link: listItem.fields.link,
      text: listItem.fields.DisplayName,
      price: listItem.fields.price
    };
  }

  const layout = {
    background: Background.value,
    padding: Padding.value,
  }

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: parseInt(totalCardsInRow.value),
    slidesToScroll: parseInt(totalCardsInRow.value),
    responsive: [
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerPadding: '8%',
          centerMode: true,
          arrows: false
        }
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '8%',
          centerMode: true,
          arrows: false
        }
      }
    ]
  };

  if(cardDisplayType.value !== 'carousel') {
    carouselSettings.responsive.push({
      breakpoint: 500,
      settings: 'unslick'
    })
  }

  return (
    <React.Fragment>
      <Container className="cb-l-margin-large--vertical">
        <Row>
          <Heading
            level="2"
            field={title}
            className="cb-type__title cb-type--center cb-l-margin-medium--bottom"
          />
        </Row>
        <Row>
          <Col>
            <Carousel settings={carouselSettings} type="spaced">
              {items &&
                items.map(listItem => {
                  const model = setModel(listItem);
                  return <ProductCard model={model} type={commerceCardType} isLoading={isLoading} />;
                })}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
};

export default CommerceCards;
