import React from 'react';
import { RetailDetail } from 'cb-design-system';
import { Preview } from "../EatMenuDetail"
import { connect } from 'react-redux';
import { ducks } from "../../ducks";

const { retail: retailDuck, cart: cartDuck, error: errorDuck, wishlist: wishlistDuck } = ducks;

const { operations } = retailDuck
const { addToRecentlyViewed } = operations
const { operations: cartOperations } = cartDuck
const { postItem: duckPostCart } = cartOperations

const errorOperations = errorDuck.operations;
const receiveError = errorOperations.receiveError;
const handleWishlist = wishlistDuck.operations.handleWishlist

const RetailProductDetail = (props) => {

  return (
    <React.Fragment>
      <Preview/>
      <RetailDetail {...props}/>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    retail : state.retail,
    editCartItem: state.cart.saveForEdit,
  };
}

const mapDispatchToProps = (dispatch) => ({
  addToRecentlyViewed: (a) => dispatch(addToRecentlyViewed(a)),
  postItem: (postData) => dispatch(duckPostCart(postData)),
  recieveError: (err) => dispatch(receiveError(err)),
  handleFavorite: (productId, isAdd) => dispatch(handleWishlist(productId, isAdd))
});

export default connect(mapStateToProps, mapDispatchToProps)(RetailProductDetail);

