import React from 'react';
import { ReactReduxContext } from 'react-redux';
import { Breadcrumb as DsBreadcrumb } from 'cb-design-system';

const Breadcrumb = (props) => {

  const links = props?.fields?.items;

  return (
    <ReactReduxContext.Consumer>
      {((context) => {
        return (
        <DsBreadcrumb
          store={context.store}
          links={links}
        />
      )}).bind(this)
      }
    </ReactReduxContext.Consumer>
  );
};

export default Breadcrumb;


