import { withLDProvider } from "launchdarkly-react-client-sdk";
import React  from 'react';
function Content(props) {
    return <>{props.children}</>;
}

const defaultContext = {
    kind: "user",
    key: "user-key-withLdProvider",
};

const config = {
    clientSideID: "",
    context: {},
    options: {bootstrap: 'localStorage'},
};

function WithLDProviderContent({ id, children, context = defaultContext, options }) {
    config.clientSideID = id;
    config.context = context;
    console.log("LaunchDarkly - WithLDProviderContent", config);
    const Provider = withLDProvider(config)(Content);
    return <Provider>{children}</Provider>;
}

export default WithLDProviderContent;
