import React from 'react';
import { connect } from 'react-redux';
import { UpsellCardList } from 'cb-design-system';
import { Preview } from "../EatMenuDetail"

import { ducks } from "../../ducks";

const {
  menu: menuDuck,
  cart: cartDuck
} = ducks;
const { operations: menuOerations } = menuDuck;
const { operations: cartOperations } = cartDuck;
const { postItem: duckPostCart } = cartOperations;

const {
  getUpsell: duckGetUpsell
} = menuOerations

const UpsellProductCards = (props) => {

  return(
    <>
    <Preview isCart={true}/>
    <UpsellCardList {...props}/>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    upsell: state.menu.upsell
  };
}

const mapDispatchToProps = (dispatch) => ({
  getUpsell: (datasource) => dispatch(duckGetUpsell(datasource)),
  addToOrder: (postData) => dispatch(duckPostCart(postData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpsellProductCards);
