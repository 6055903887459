import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect, ReactReduxContext } from 'react-redux';
import { WaitlistForm, Wrapper, Container } from 'cb-design-system';

import { ducks } from '../../ducks';

import { AzureADClient, authProviderClient } from '../../AuthComponent';

const locationOperations = ducks.location.operations

class WaitList extends Component {

  constructor(props) {
    super(props);

    this.handleLocationSuccess = this.handleLocationSuccess.bind(this);
    this.handleLocationError = this.handleLocationError.bind(this);
  }

  componentDidMount() {
    if (!this.props.latLon) {
      // this.props.changeLatLon(this.props.latLon);
    }

    // TODO: Store location in cookie to avoid repeated lookups
    // this.props.getLocationByGps();
  }

  componentWillReceiveProps(newProps){
    if(newProps.latLon !== this.props.latLon){
      // this.props.changeLatLon(newProps.latLon);
      // this.props.getLocationByGps();
    }

    if(newProps.account !== this.props.account){

      this.setState({
        accoount: newProps.account
      })
    }
  }

  handleLocationSuccess(position) {
    if(typeof position.coords === 'object') {
      // this.props.changeLatLon(position.coords);
      // this.props.getLocationByGps();
    }
  }

  handleLocationError(err) {
    this.props.handleError(err)
  }

  render() {
    const {
      Background = {}, // default, solid
      Padding = {}, // xxsmall, xsmall, small, medium, large, xlarge, xxlarge
    } = this.props.fields;

    const layout = {
      background: Background.value,
      padding: Padding.value
    }

    return(
      <ReactReduxContext.Consumer>
        {((context) => {
          return (
            <AzureADClient provider={authProviderClient} reduxStore={context.store}>
              {
                ({login, logout, authenticationState, error, accountInfo}) => {
                  return(
                    <Wrapper layout={layout}>
                      <Container>
                        <WaitlistForm
                          authenticationState={authenticationState}
                          accountInfo={accountInfo}
                          results={this.props.results}
                          isFetching={this.props.isFetching}
                          isError={this.props.isError}
                          err={this.props.err}
                          onSuccess={this.handleLocationSuccess}
                          onError={this.handleLocationError}
                          disclaimer={this.props.fields.Disclaimer}
                        />
                      </Container>
                    </Wrapper>)
                }
              }
            </AzureADClient>
          )}
        ).bind(this)
      }
      </ReactReduxContext.Consumer>
    );
  }
};

WaitList.propTypes = {
  latLon: PropTypes.object.isRequired,
  getLocationByIp: PropTypes.func.isRequired,
  getLocationByGps: PropTypes.func.isRequired,
  disclaimer: PropTypes.string
}

const mapStateToProps = (state) => {
  return {
    latLon: state.location.latLon,
    isFetching: state.location.isFetchingLocation,
    isError: state.location.isError,
    isLoading: state.waitlist.isLoading,
    results: state.location.locationResults,
    err: state.location.err
  };
}

const mapDispatchToProps = (dispatch) => ({
  changeLatLon: text => dispatch(locationOperations.changeLatLon(text)),
  getLocationByIp: () => dispatch(locationOperations.getLocationByIp()),
  getLocationByGps: () => dispatch(locationOperations.getLocationByGps()),
  handleError: err => dispatch(locationOperations.receiveError(err)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WaitList);
