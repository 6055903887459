import React from 'react';
import { LocationMap as DesignSystemLocationMap, Wrapper } from 'cb-design-system';

const OurMenu = () => (
  <Wrapper>
    <DesignSystemLocationMap src="https://hosted.where2getit.com/crackerbarrel/menulocator.html"/>
  </Wrapper>
)

export default OurMenu;
