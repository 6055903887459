import React from 'react';
import { RetailRecentlyViewed } from 'cb-design-system';
import { connect } from 'react-redux';

const RecentlyViewed = (props) => {
  return (
    <RetailRecentlyViewed
      {...props}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    retail : state.retail,
    recentlyViewed: state.retail.recentlyViewed || []
  };
}

export default connect(mapStateToProps)(RecentlyViewed);




