import React from 'react';
import { ContentBlock as DesignContentBlock } from 'cb-design-system';

const ContentBlock = (props) => {

  const {
    image = {},
    videoUrl = {},
    ctaLink = {},
    linkType = {},
    title = {},
    titleTag = {},
    subtitle = {},
    publishedDate = {},
    text = {},
    showMoreText = {},
    TextAlignment = {}, // left, center, right
    Background = {}, // default, solid
    TopPadding = {},
    BottomPadding = {}
  } = props.fields;

  const model = {
    image: image,
    videoUrl: videoUrl,
    title: title,
    ctaLink: ctaLink,
    linkType: linkType.value,
    titleTag: titleTag.value,
    subtitle: (subtitle.value === '' || typeof subtitle.value === 'undefined') ? publishedDate : subtitle,
    text: text,
    showMoreText: showMoreText
  }

  const layout = {
    background: Background?.value || 'solid',
    topPadding: TopPadding?.fields?.key.value || 'medium',
    bottomPadding: BottomPadding?.fields?.key.value || 'medium',
  }

  return (
    <DesignContentBlock {...props} model={model} alignment={TextAlignment.value} layout={layout} />
  )
};

export default ContentBlock;
