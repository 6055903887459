import React from 'react';
import { Wrapper, ContentBlock, CTALink } from 'cb-design-system';

/**
 * Demonstrates usage of a Content List field type within JSS.
 * Content Lists are references to 0..n other content items.
 * In Sitecore terms, this maps by default to a Treelist field.
 */
const List = (props) => {
  const {
    fields = {},
    items = [],
    ctaLink = {},
    linkType = {},
  } = props.fields.data;

  const setModel = (listItem) => {
  return {
      image: listItem.image.jss,
      videoUrl: listItem.videoUrl.jss,
      ctaLink: listItem.ctaLink.jss,
      linkType: listItem.linkType.jss.value,
      title: listItem.title.jss,
      titleTag: listItem.titleTag.jss.value,
      subtitle: listItem.subtitle.jss,
      text: listItem.text.jss,
    }
  }

  const setLayout = (listItem) => {
    return {
      background: listItem.background.jss.value,
      topPadding: listItem.topPadding.jss.fields.key.value,
      bottomPadding: listItem.bottomPadding.jss.fields.key.value,
      type: 'list'
    }
  }

  const mainLayout = {
    background: fields.background.jss.value,
    topPadding: fields.topPadding.jss.fields.key.value,
    bottomPadding: fields.bottomPadding.jss.fields.key.value,
    alignment: fields.textAlignment.jss.value,
  }

  return (
    <Wrapper layout={mainLayout} alignment={mainLayout.alignment}>
      <div className="cb-content-block-list">
        {
          items && items.children && items.children.map((listItem) => {
            const model = setModel(listItem);
            const layout = setLayout(listItem);
            return (
              <ContentBlock model={model} alignment={mainLayout.alignment} layout={layout} />
            )
          })
        }
        <CTALink
          ctaLink={ctaLink}
          linkType={linkType}
          containerClass="cb-content-block__cta"
          alignment={mainLayout.alignment}
        />
      </div>
    </Wrapper>
  );
};

export default List;


