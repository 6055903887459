import React, { useState } from 'react';
import { AccordionFilter as DesignAccordionFilter } from 'cb-design-system';

const AccordionFilter = (props) => {
  const layout = {
    background: props.fields.Background?.value || 'solid',
    topPadding: props.fields.TopPadding?.fields.key.value || 'medium',
    bottomPadding: props.fields.BottomPadding?.fields.key.value || 'medium',
  }

  return <DesignAccordionFilter {...props} layout={layout}/>
}

export default AccordionFilter;

