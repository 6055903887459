import { ducks as DesignDucks } from "cb-design-system";

const accountDuck = DesignDucks.account;
const errorDuck = DesignDucks.error;
const locationDuck = DesignDucks.location;
const newsletterDuck = DesignDucks.newsletter;
const waitlistDuck = DesignDucks.waitlist;
const headerDuck = DesignDucks.header;
const articleDuck = DesignDucks.article;
const searchDuck = DesignDucks.search;
const menuDuck = DesignDucks.menu;
const cartDuck = DesignDucks.cart;
const retailDuck = DesignDucks.retail;
const wishlistDuck = DesignDucks.wishlist;
const offlineDuck = DesignDucks.offline;
const mobilepayDuck = DesignDucks.mobilepay;
const loyaltyDuck = DesignDucks.loyalty;
const rewardsDuck = DesignDucks.rewards;
const personalizeDuck = DesignDucks.personalize;


const ducks = {
  account: accountDuck,
  error: errorDuck,
  location: locationDuck,
  newsletter: newsletterDuck,
  search: searchDuck,
  waitlist: waitlistDuck,
  header: headerDuck,
  article: articleDuck,
  menu: menuDuck,
  cart: cartDuck,
  retail: retailDuck,
  wishlist: wishlistDuck,
  offline: offlineDuck,
  mobilepay: mobilepayDuck,
  loyalty: loyaltyDuck,
  rewards: rewardsDuck,
  personalize: personalizeDuck
};

export { ducks };
