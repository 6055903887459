import React, { Fragment } from "react";
import { ReactReduxContext } from "react-redux";
import { authProviderClient, AzureADClient, AuthenticationState } from "../../AuthComponent";
import { Button, Heading } from "cb-design-system";


const SweepStakes = (props) => {

  const sweepStakes = props?.rendering?.fields
  const {
    "Form Header": { value: formHeader },
    "Redirect Url": { value: redirectUrl },
    "IsActive": { value: isActive },
    "StartDateTimeUTC": { value: startDateTimeUTC },
    "EndDateTimeUTC": { value: endDateTimeUTC },
    "Enter To Win Text": { value: enterToWinText },
    "Cancel Text": { value: cancelText },
    "SweepStakes URL": { value: sweepStakesURL },
    "Disclaimer Text": { value: disclaimerText },
    "Image": { value: image },
    "Logo Image": { value: logoImage },
    "Close Image": { value: closeImage }
  } = sweepStakes;


  const nowLocal = new Date();
  const nowUTC = new Date(Date.UTC(
    nowLocal.getUTCFullYear(),
    nowLocal.getUTCMonth(),
    nowLocal.getUTCDate(),
    nowLocal.getUTCHours(),
    nowLocal.getUTCMinutes(),
    nowLocal.getUTCSeconds(),
    nowLocal.getUTCMilliseconds()
  ));
  const startTime = new Date(startDateTimeUTC);
  const endTime = new Date(endDateTimeUTC);

  const activeDate = (nowUTC >= startTime && nowUTC <= endTime)

  const getCookieValue = name => {
    return (
      document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() ||
      ""
    );
  };

  const createCookie =  (cookieName, cookieValue, expirationDays) => {
    var date = new Date();
    date.setTime(date.getTime() + expirationDays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + date.toUTCString();
    document.cookie =
      cookieName + "=" + cookieValue + "; " + expires + "; path=/;";
  }

  const isOnSweepstakePage = window.location.pathname.startsWith('/sweepstake');
  const alreadySeenSweepstake = getCookieValue('hasSeenSweepstake') === "true";
  const canShowSweepStake = activeDate && isActive && !alreadySeenSweepstake

  if(canShowSweepStake){
     window.sessionStorage.setItem("redirectToSweepstakeOnLogin",canShowSweepStake)
  }

  if (!isActive || !activeDate && isOnSweepstakePage) {

    if((!isActive && !isOnSweepstakePage) == false){
        window.location = redirectUrl
    }
  }

  if(isOnSweepstakePage){
    createCookie(
      "hasSeenSweepstake",
      "true",
      365
    );
  }

  
  if(!isOnSweepstakePage){
    return <div></div>
  }

  return (
    <Fragment>
      <ReactReduxContext.Consumer>
        {((context) => {
          return (
            <AzureADClient
              forceLogin={false}
              provider={authProviderClient}
              reduxStore={context.store}
            >
              {({ authenticationState, error }) => {
                if (error != null) {
                  console.error(error);
                }
                switch (authenticationState) {
                  case AuthenticationState.Authenticated:
                    break;
                  default:
                    if(isOnSweepstakePage)
                      window.location = redirectUrl
                    break;
                }

                return (

                  <div className="container" style={{ maxWidth: '600px', minHeight: '100vh' }}>
                    {authenticationState === AuthenticationState.Authenticated ?
                      <div>
                        <div className="d-flex justify-content-end">
                          <div id="RSAlert" style={{ background: 'none' }}>
                            <button className="closeRSAlert" onClick={() => { window.location = redirectUrl }}>&#10005;</button>
                          </div>
                        </div>
                        <div className="text-center">
                          <Heading
                            level="2"
                            field={{ value: formHeader }}
                            className="cb-type__title cb-type--cecloseRSAlertnter cb-l-margin-medium--bottom cb-color-red-900 mb-4"
                          />

                          <div >
                            <img src={logoImage?.src} alt={logoImage?.alt} height={logoImage?.height} width={logoImage?.alt} className="img-fluid mb-4" style={{ width: '50%' }} />
                          </div>
                          <div>
                            <img src={image?.src} alt={image?.alt} height={image?.height} width={image?.alt} className="img-fluid" style={{ minWidth: '100%' }} />
                          </div>
                          <p className="cb-banner__text mb-4">
                            {disclaimerText}
                          </p>
                          <div className="mt-3">
                            <div>
                              <Button onClick={(e) => { window.open(sweepStakesURL.url, '_blank', "noopener, noreferrer") }} className="cb-button-primary w-100 mb-2">{enterToWinText}</Button>
                            </div>
                            <div>
                              <Button onClick={(e) => { window.location = redirectUrl }} className="cb-button-secondary w-100 mb-2">{cancelText}</Button>
                            </div>
                          </div>
                        </div>
                      </div> : 
                      null}
                  </div>

                );
              }}
            </AzureADClient>
          );
        }).bind(this)}
      </ReactReduxContext.Consumer>
    </Fragment>
  );
};

export default SweepStakes;
