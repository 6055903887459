import { Component } from 'react';

class DummyPersist extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return(
      this.props.children
    );
  }
};


export default DummyPersist;
