import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import React, { Fragment } from "react";
import { ReactReduxContext } from "react-redux";
import { AzureADClient, authProviderClient } from "../../AuthComponent";
import { JustForYouListing as BsJustForYouListing } from "cb-design-system";

const JustForYou = props => {
  const { fields } = props || {};

  return (
    <Fragment>
      <ReactReduxContext.Consumer>
        {(context => {
          return (
            <AzureADClient
              provider={authProviderClient}
              reduxStore={context.store}
            >
              {({ authenticationState, error, accountInfo }) => {
                if (error != null) {
                  console.error(error);
                }

                return (
                  <BsJustForYouListing
                    authenticationState={authenticationState}
                    store={context.store}
                    accountInfo={accountInfo}
                    fields={fields}
                  />
                );
              }}
            </AzureADClient>
          );
        }).bind(this)}
      </ReactReduxContext.Consumer>
    </Fragment>
  );
};

export default withSitecoreContext()(JustForYou);
