import React, { Component } from 'react';
import axios from "axios";
import PropTypes from 'prop-types';
import { connect, ReactReduxContext } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import { HeaderNav, getSessionStorageKey } from 'cb-design-system';
import { ducks } from '../../ducks';

import { AzureADClient, authProviderClient, AuthenticationState } from '../../AuthComponent';
import { authConfig } from '../../AuthProvider';

const accountOperations = ducks.account.operations
const headerOperations = ducks.header.operations
const searchOperations = ducks.search.operations
const retailOperations = ducks.retail.operations
const loyaltyOperations = ducks.loyalty.operations
const personalizeOperations = ducks.personalize.operations
const { cart: cartDuck } = ducks;
const { operations } = cartDuck
const { closeAddTo, getCart: duckGetCart } = operations


export const HeaderNavComponent = (props) => {
  const {context, store, authenticationState, accountInfo, handleLogout} = props


  return (
  <HeaderNav
    {...props}
    store={store}
    handleLogout={handleLogout}
    navigation={context.navigationItems}
    model={context.model}
    hamburgerMenuLinks={context.hamburgerMenuLinks}
    hamburgerMenuButtons={context.hamburgerMenuButtons}
    searchResults={context.props.results}
    toggleWaitlist={context.props.toggleWaitlist}
    accountNavVisible={context.props.accountNavVisible}
    secondaryNavVisible={context.props.secondaryNavVisible}
    toggleSidenav={context.props.toggleSidenav}
    searchChangeHandler={context.handleInputChange}
    searchSubmitHandler={context.handleSearch}
    authenticationState={authenticationState}
    accountInfo={accountInfo}
    handleLogin={context.handleLogin}
  />
)}

class Header extends Component {

  constructor(props) {
    super(props);

    this.fields = props.fields?.data?.fields  || {} ;
    this.navigationItems = props.fields?.data?.navigationItems || {children: []};
    this.hamburgerMenuLinks  = props.fields?.data?.hamburgerMenuLinks || {children: []};
    this.hamburgerMenuButtons = props.fields?.data?.hamburgerMenuButtons || {children: []};
    this.handleInputChange = this.handleInputChange.bind(this);
    this.sendPageViewEvent = this.sendPageViewEvent.bind(this);

    const { cookies } = props;

    this.state = {
      loggedin: cookies.get('scLoggedin') || false,
      getAccountInfoState: false,
      engageAvailable: false,
      releasePersonalization: false
    };

    this.model = {
      logo: {
        image: this.fields.logo?.jss || {},
        url: this.fields.logoUrl?.jss || {}
      },
      pegPointLinkNotLoggedIn: this.fields.pegPointLinkNotLoggedIn?.jss,
      pegPointLinkLoggedIn: this.fields.pegPointLinkLoggedIn?.jss,
      pegPointAlt: this.fields.pegPointAlt?.jss,
      pegIconAlt: this.fields.pegIconAlt?.jss,
      search: {
        searchPlaceholder: this.fields.searchPlaceholder?.jss || {},
        searchResultPageUrl: this.fields.searchResultPageUrl?.jss || {}
      },
      account: {
        signInButtonText: this.fields.signInButtonText?.jss || {},
        registerButtonText: this.fields.registerButtonText?.jss || {},
        createAccountModelTitle: this.fields.createAccountModelTitle?.jss || {},
        dontHaveAnAccountText: this.fields.dontHaveAnAccountText?.jss || {},
        signInLink: this.fields.signInLink?.jss || {}
      },
      searchChangeHandler: this.handleInputChange,
    }
  }

  componentDidMount() {

    const queryRedirect = window.localStorage.getItem("LogoutRedirectUrl") || null;
    window.localStorage.removeItem("LogoutRedirectUrl");
    // Get the current URL
    const redirectURL = window.location.pathname
    // Get the query string part of the URL
    const queryParams = window.location.search;

    if (queryRedirect && queryRedirect !== redirectURL + queryParams) {
      window.history.pushState({}, '', window.location.origin + queryRedirect);
      window.location = window.location.origin + queryRedirect
    }

    const {enteredSearchText, changeEnteredSearchText, setPageSize, query } = this.props;

    if (!enteredSearchText && query) {
      changeEnteredSearchText(query, 7);
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.state.loggedin === 'true' && this.props.loggedin && this.props.account?.aadResponse?.jwtIdToken) {
      if (!this.props.loyalty?.account?.FirstName && this.props.account?.aadResponse?.account?.idToken?.signInName && !this.state.getAccountInfoState) {
        this.setState({ getAccountInfoState: true })
        this.props.getAccountInformation()
      }
    }
    
    // Remove account orders tabs local storage if the user navigates to another url to have order history as default option when the user get back to the account orders screen
    if (window.localStorage.getItem('accountOrderTab') && window.location.pathname.indexOf('my-orders') === -1) {
      window.localStorage.removeItem('accountOrderTab');
    }

    if (this.props.flags?.['releasePersonalization'] !== this.state.releasePersonalization) {
      this.setState({ releasePersonalization: this.props.flags?.['releasePersonalization'] })
    }

    if (this.state.releasePersonalization && !prevState.releasePersonalization) {
      if (this.state.engageAvailable) {
        this.sendPageViewEvent(window.location.href, this.props.loggedin, this.props.locationId)
      } else {
        // Set up a timer to periodically check for window.engage availability
        this.checkEngageAvailability = setInterval(() => {
          if (window.engage) {
            // window.engage is available, update state
            this.setState({ engageAvailable: true });
            clearInterval(this.checkEngageAvailability); // Stop the timer
          }
        }, 100);
      }
    }

    if (this.state.releasePersonalization) {
      // Perform actions when engage becomes available
      if (this.state.engageAvailable && !prevState.engageAvailable) {
        this.sendPageViewEvent(window.location.href, this.props.loggedin, this.props.locationId)
      } else if (this.state.engageAvailable && (this.props.loggedin !== prevProps.loggedin || this.props.locationId !== prevProps.locationId)) {
        this.sendPageViewEvent(window.location.href, this.props.loggedin, this.props.locationId)
      }
    }
  }
  
  componentWillUnmount() {
    // Clear the timer when the component unmounts
    if (this.checkEngageAvailability) {
      clearInterval(this.checkEngageAvailability);
    }
  }

  componentWillReceiveProps(newProps) {
     if(newProps.loggedin !== this.props.loggedin){
      if(!this.state.loggedin) {
        if(newProps.account?.aadResponse?.jwtIdToken) {
          this.props.postAccount(newProps.account.aadResponse.jwtIdToken);
          const { cookies } = this.props;
          cookies.set('scLoggedin', true, { path: '/' });
          this.state = {
            loggedin: true
          };
        }
      }
    }
  }

  handleLogin() {
    if (this.model.account.signInLink?.value?.href) {
      // Get the current URL
      const redirectURL = window.location.pathname
      // Get the query string part of the URL
      const queryParams = window.location.search;

      window.localStorage.setItem("LoyaltyLogInRedirectUrl", redirectURL + queryParams)
      
      window.location.href = this.model.account.signInLink.value.href
    }
  }

  handleInputChange(value) {
    if (this.props.enteredSearchText !== value) {
      this.props.query = value;
      this.props.changeEnteredSearchText(this.props.query, 7);
    }
  }

  handlePasswordReset = (error) => {
    authProviderClient.setAuthenticationParameters ({
      authority: `${authConfig.domain}/${authConfig.mfaPasswordPolicy}`,
    })

    authProviderClient.login()
  }

  /**
   * Send page view event to Engage
   * @param page
   * @param loggedIn
   * @param store
   * @returns {Promise<void>}
   */
  sendPageViewEvent = async (page, loggedIn, store = null) => {
    const event = {
      channel: 'WEB',
      language: 'EN',
      currency: 'USD',
      page: page,
    }

    const date = new Date().toString()
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const urlParams = new URLSearchParams(window.location.search)
    const partnerId = urlParams.get('partnerId')
    const campaignId = urlParams.get('campaignId')
    const personalizeState = getSessionStorageKey('personalize')

    const extensionData = {
      userLoggedIntoLoyalty: loggedIn,
      browserCurrentDateTime: date,
      browserCurrentTimeZone: timeZone,
      partnerId: personalizeState?.pageViewData?.extensionData?.partnerId || partnerId || null,
      campaignId: personalizeState?.pageViewData?.extensionData?.campaignId || campaignId || null,
    }
    
    if (partnerId && extensionData.partnerId !== partnerId) {
      extensionData.partnerId = partnerId
    }

    if (campaignId && extensionData.campaignId !== campaignId) {
      extensionData.campaignId = campaignId
    }
    
    if (store) {
      extensionData.selectedStoreNumber = store
    }

    // Send VIEW event
    if (typeof window !== 'undefined' && typeof window.engage !== 'undefined') {
      try {
        await window.engage.pageView(event, extensionData)
        this.props.setPageViewData({
          event: event,
          extensionData: extensionData
        })
      } catch (error) {
        console.error('Error sending page view event:', error)
      }
    }
  }

  render() {
    return(
      <ReactReduxContext.Consumer>
        {((context) => {
          return (
            <AzureADClient forceLogin={false} provider={authProviderClient} reduxStore={context.store}>
            {
              ({login, logout, authenticationState, error, accountInfo}) => {

                if(error  && error?.errorMessage?.indexOf("AADB2C90118") >= 0) {
                  this.handlePasswordReset(error);
                }

                if(error  && error?.errorMessage?.indexOf("AADB2C90091") >= 0) {
                 // user cancelled
                }

                const token = this.props.account?.aadResponse?.jwtIdToken;
                if(token) {
                  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                } else {
                  delete axios.defaults.headers.common['Authorization'];
                }
                return (
                  <HeaderNavComponent
                    {...this.props}
                    store={context.store}
                    authenticationState={authenticationState}
                    accountInfo={accountInfo}
                    handleLogout={logout}
                    context={this}
                  />
                )
              }}
            </AzureADClient>
          )}
        ).bind(this)
      }
      </ReactReduxContext.Consumer>
    );
  }
};

Header.propTypes = {
  changeEnteredSearchText: PropTypes.func,
  enteredSearchText: PropTypes.string,
  changeQueryText: PropTypes.func
}

const mapStateToProps = (state) => {

  return {
    account: state.account,
    loggedin: state.account?.loggedin,
    waitlistActive: state.header.waitlistActive,
    waitlistAvailable: state.location.location?.waitlist_flag && (state.location.location.webaheadstatus === 'Available'),
    orderOnline: state.location.location?.OnlineOrdering,
    temporarilyClosed: state.location.location?.TemporarilyClosed,
    secondaryNavVisible: state.header.secondaryNavVisible,
    accountNavVisible: state.header.accountNavVisible,
    enteredSearchText: state.search.enteredSearchText,
    query: state.search.searchTerm,
    isFetching: state.search.isFetching,
    results: state.search.searchResults,
    addTo : state.cart.addedToCart,
    newItem: state.cart.newItem || {},
    shopNav: state.retail?.retailCategories || [],
    offlineCart: state.offline.cart,
    loyalty: state.loyalty,
    locationId: state.location?.location?.vendorid || null
  };
}

const mapDispatchToProps = (dispatch) => ({
  toggleWaitlist: () => dispatch(headerOperations.toggleWaitlist()),
  toggleSidenav: () => dispatch(headerOperations.toggleSidenav()),
  changeEnteredSearchText: (text, pageSize) => dispatch(searchOperations.termChange(text, pageSize)),
  performSearch: () => dispatch(searchOperations.termChange()),
  changeQueryText: (text, pageSize) => dispatch(searchOperations.termChange(text, pageSize)),
  setPageSize: pageSize => dispatch(searchOperations.setPageSize(pageSize)),
  close: () => dispatch(closeAddTo()),
  postAccount: token => dispatch(accountOperations.postAccount(token)),
  getShopCategories: () => dispatch(retailOperations.getCategories()),  
  getCart: (jwtIdToken) => dispatch(duckGetCart(jwtIdToken)),
  getAccountInformation: () => dispatch(loyaltyOperations.handleGetAccountInformation()),
  setPageViewData: (data) => dispatch(personalizeOperations.setPageViewData(data))
});

const EnhancedHeader = connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(Header));

// Wrap the enhanced component with withLDConsumer HOC
export default withLDConsumer()(EnhancedHeader);