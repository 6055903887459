export default {
  "authentication": {
    "local": {
      "mfaPolicy": "B2C_1A_MFA_Dynamic_SignUp_SignIn",
      "mfaProfilePolicy": "B2C_1A_MFA_ProfileEdit",
      "mfaPasswordPolicy": "B2C_1A_MFA_PasswordReset",
      "clientId": 'df178d25-15f3-45db-b736-ab52a27dbac2',
      "domain": "https://cbdigitalqa.b2clogin.com/cbdigitalqa.onmicrosoft.com",
    },
    "develop": {
      "mfaPolicy": "B2C_1A_MFA_Dynamic_SignUp_SignIn",
      "mfaProfilePolicy": "B2C_1A_MFA_ProfileEdit",
      "mfaPasswordPolicy": "B2C_1A_MFA_PasswordReset",      
      "clientId": 'df178d25-15f3-45db-b736-ab52a27dbac2',
      "domain": "https://cbdigitalqa.b2clogin.com/cbdigitalqa.onmicrosoft.com"
    },
    "development": {
      "mfaPolicy": "B2C_1A_MFA_Dynamic_SignUp_SignIn",
      "mfaProfilePolicy": "B2C_1A_MFA_ProfileEdit",
      "mfaPasswordPolicy": "B2C_1A_MFA_PasswordReset",
      "clientId": 'df178d25-15f3-45db-b736-ab52a27dbac2',
      "domain": "https://cbdigitalqa.b2clogin.com/cbdigitalqa.onmicrosoft.com"
    },
    "qa": {
      "mfaPolicy": "B2C_1A_MFA_Dynamic_SignUp_SignIn",
      "mfaProfilePolicy": "B2C_1A_MFA_ProfileEdit",
      "mfaPasswordPolicy": "B2C_1A_MFA_PasswordReset",
      "clientId": 'df178d25-15f3-45db-b736-ab52a27dbac2',
      "domain": "https://cbdigitalqa.b2clogin.com/cbdigitalqa.onmicrosoft.com",
    },
    "stage": {
      "mfaPolicy": "B2C_1A_MFA_Dynamic_SignUp_SignIn",
      "mfaProfilePolicy": "B2C_1A_MFA_ProfileEdit",
      "mfaPasswordPolicy": "B2C_1A_MFA_PasswordReset",
      "clientId": 'df178d25-15f3-45db-b736-ab52a27dbac2',
      "domain": "https://cbdigitalqa.b2clogin.com/cbdigitalqa.onmicrosoft.com",
    },
    "production": {
      "mfaPolicy": "B2C_1A_MFA_Dynamic_SignUp_SignIn",
      "mfaProfilePolicy": "B2C_1A_MFA_ProfileEdit",
      "mfaPasswordPolicy": "B2C_1A_MFA_PasswordReset",
      "clientId": '40335d1d-0ee8-437c-906a-55e94f2022c6',
      "domain": "https://cbdigitalprd.b2clogin.com/cbdigitalprd.onmicrosoft.com"
    }
  }
}
