import React from 'react';
import { OfflineShop as OffShop } from 'cb-design-system';
import { connect, ReactReduxContext } from 'react-redux';
import { ducks } from "../../ducks";


const { offline: offlineDuck } = ducks;
const { operations } = offlineDuck;

const OfflineShop = (props) => {
            return (

                <OffShop
                    {...props}
                />
            )
}

const mapStateToProps = (state) => {

    console.log('state in offshop',state);

    return {
        retailProducts: state.offline.retailProducts,
        loading: state.offline.isLoading,
        addedToCart: state.offline.addedToCart
    };
}

const mapDispatchToProps = (dispatch) => ({
    searchRetailProduct: (productId) => (dispatch(operations.searchRetailProduct(productId))),
    addItemsToCart: (cartItem, variant) => (dispatch(operations.addItemsToCart(cartItem, variant))),
    clearRetailStates: () => (dispatch(operations.clearRetailStates())),
    closeAddTo: () => (dispatch(operations.closeAddTo())),
    resetAssign: () => (dispatch(operations.resetAssign()))
});

export default connect(mapStateToProps, mapDispatchToProps)(OfflineShop);