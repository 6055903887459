import React from 'react';
import { RetailWriteAReview  } from 'cb-design-system';

const RetailWriteProductReviews = (props) => {
  //Need to move reviews to here
  return (
    <RetailWriteAReview/>
  );
};

export default RetailWriteProductReviews;


