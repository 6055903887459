import React from 'react';
import { Accordion as DesignAccordion } from 'cb-design-system';

// JSS
import { Link } from '@sitecore-jss/sitecore-jss-react';


/**
 * Demonstrates usage of a Content List field type within JSS.
 * Content Lists are references to 0..n other content items.
 * In Sitecore terms, this maps by default to a Treelist field.
 */
const Accordion = (props) => {
  const {
    Background = {},
    TopPadding = {},
    BottomPadding = {},
    title,
    text,
    ctaLink = {},
    items = {},
    linkType = {}
  } = props.fields;

  const layout = {
    background: Background.value,
    topPadding: TopPadding?.fields.key.value || 'medium',
    bottomPadding: BottomPadding?.fields.key.value || 'medium',
  }

  const titleModel = {
    title: title,
    text: text
  }

  return (
    <React.Fragment>
      <DesignAccordion model={items} layout={layout} title={titleModel} ctaLink={ctaLink} linkType={linkType}/>
    </React.Fragment>
  );
};

export default Accordion;


