import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import { createStore, compose, applyMiddleware } from 'redux';
import createEncryptor from 'redux-persist-transform-encrypt'
// import storage from 'redux-persist/lib/storage';
import session from 'redux-persist/lib/storage/session';
import { CookieStorage } from 'redux-persist-cookie-storage'
import Cookies from 'js-cookie';

import thunk from 'redux-thunk';

import { ducks }  from './ducks';

const expireReducer = require('redux-persist-expire');

const encryptor = createEncryptor({
  secretKey: 'cb-test-dev-key',
  onError: function(error) {
    // Handle the error.
  }
});

const accountReducer = ducks.account.reducer;
const errorReducer = ducks.error.reducer;
const locationReducer = ducks.location.reducer;
const searchReducer = ducks.search.reducer;
const waitlistReducer = ducks.waitlist.reducer;
const newsletterReducer = ducks.newsletter.reducer;
const headerReducer = ducks.header.reducer;
const articleReducer = ducks.article.reducer;
const menuReducer = ducks.menu.reducer;
const cartReducer = ducks.cart.reducer;
const retailReducer = ducks.retail.reducer;
const wishlistreducer = ducks.wishlist.reducer;
const offlineReducer = ducks.offline.reducer;
const mobilepayReducer = ducks.mobilepay.reducer;
const loyaltyReducer = ducks.loyalty.reducer;
const rewardsReducer = ducks.rewards.reducer;
const personalizeReducer = ducks.personalize.reducer;

const transforms = null; // Turning encryption off for now //(process.env.NODE_ENV === 'production') ? [encryptor] : null;

const generalPersistConfig = {
  key: 'cb-ds',
  storage: session,
  blacklist: ['error','newsletter','header','location', 'waitlist', 'retail'],
  transforms: transforms
}

const cookieConfig = {
  key: 'cb-ds-cookie',
  storage: new CookieStorage(Cookies, { expiration: { 'default': 365 * 86400  }}),
  whitelist: ['cookie'],
}

const cookieAccountConfig = {
  key: 'cb-ds-cookie',
  storage: new CookieStorage(Cookies),
  whitelist: ['cookie'],
}

const locationPersistConfig = {
  key: 'cb-ds-loc',
  storage: session,
  blacklist: ['zipcode', 'err', 'isError', 'isLoading'],
  transforms: transforms
}

const waitlistPersistConfig = {
  key: 'cb-ds-wait',
  storage: session,
  blacklist: ['err', 'isError', 'isLoading', 'waitlistActive'],
  transforms: transforms
}

const retailPersistConfig = {
  key: 'cb-ds-retail',
  storage: session,
  blacklist: ['pageSize', "selectedFacets", "sortOrder", "sortBy", "products", "cards", "facets", "totalPageCount", "totalRetail"],
  transforms: transforms
}

const searchPersistConfig = {
  key: 'cb-ds-search',
  storage: session,
  whitelist: ['sortBy','currentPage'],
  transforms: transforms
}

const rootReducer = combineReducers({
  location: persistReducer(locationPersistConfig, locationReducer),
  cbLocation: persistReducer(cookieConfig, locationReducer),
  waitlist: persistReducer(waitlistPersistConfig, waitlistReducer),
  account: accountReducer,
  cbAccount: persistReducer(cookieAccountConfig, accountReducer),
  error: errorReducer,
  search: persistReducer(searchPersistConfig, searchReducer),
  newsletter: newsletterReducer,
  header: headerReducer,
  article: articleReducer,
  menu: menuReducer,
  cart: cartReducer,
  retail: persistReducer(retailPersistConfig, retailReducer),
  wishlist: wishlistreducer,
  offline: offlineReducer,
  mobilepay: mobilepayReducer,
  loyalty: loyaltyReducer,
  rewards: rewardsReducer,
  personalize: personalizeReducer
});

const persistedReducer = persistReducer(generalPersistConfig, rootReducer);

const enhancer = compose(applyMiddleware(thunk));
const store = createStore(persistedReducer, enhancer);
const persistor = persistStore(store);

export {
  store,
  persistor
}
