import React, { useState, useEffect } from 'react';
import {
  mapProduct,
  TextWCardList as TextWList,
  Wrapper,
  Loading,
  impressionsGA,
  gaTracker } from 'cb-design-system';
import { connect } from 'react-redux';
import { ducks } from "../../ducks";

const { cart: cartDuck, menu: menuDuck, wishlist: wishlistDuck, location: locationDuck, rewards: rewardsDuck } = ducks;
const { operations } = cartDuck
const { operations: menuOperations } = menuDuck
const { operations: locationOperations } = locationDuck;
const { postItem: duckPostCart, resetSaveForEdit } = operations
const { setDefault } = menuOperations
const rewardsOperations = rewardsDuck.operations

const handleWishlist = wishlistDuck.operations.handleWishlist

const EatCards = (props) => {
  const { results = [], currentCategory, addToOrder, noLocation, saveDefault, location, userModified, toggleChangeNoLocationModal, isLoading } = props

  const layout = {
    background: "default",
    topPadding: "medium",
    bottomPadding: "medium"
  }

  const exampleCardDetail = {
    "Name": "1",
    "DisplayName": "Loading",
    "Products": [],
    "Categories": []
  }


  const filteredToShow = results.filter(category => currentCategory.normalized === category.CategoryContentPageName.toLowerCase())
  let rendered;

  if(filteredToShow.length === 1) {
    rendered = filteredToShow[0] || exampleCardDetail
  } else {
    rendered = results || exampleCardDetail
  }

  useEffect(() => {
    // if(!userModified) {
    //   toggleChangeNoLocationModal(true)
    // }
    // console.log('Test user modified: ', userModified);
    // toggleChangeNoLocationModal(!userModified);
  }, [userModified])

  return(
  (!results.length) ? <Loading isLoading={true}/> : (Array.isArray(rendered)) ?
    <FullList {...props}
    layout={layout}
    addToOrder={addToOrder}
    noLocation={noLocation}
    saveDefault={saveDefault}
    isLoading={isLoading}
    toRender={rendered}
    location={location}/> : <SingleCategory {...props}
    layout={layout}
    addToOrder={addToOrder}
    noLocation={noLocation}
    saveDefault={saveDefault}
    toRender={rendered}
    location={location}/>)
};

const mapStateToProps = (state) => {
  return {
    results : state.menu.results,
    currentCategory: state.menu.currentCategory,
    noLocation: !(state.location?.location?.olo_unique_name || state.location?.location?.oloUniqueName),
    location: !(state.location?.location?.olo_unique_name || state.location?.location?.oloUniqueName),
    userModified: state?.location?.userModified,
    isLoading : state.cart.isLoading,
    wishlist: state.wishlist,
  };
}

const mapDispatchToProps = (dispatch) => ({
  addToOrder: (postData) => dispatch(duckPostCart(postData)),
  saveDefault: (defaultOption) => dispatch(setDefault(defaultOption)),
  handleFavorite: (productId, isAdd) => dispatch(handleWishlist(productId, isAdd)),
  toggleChangeNoLocationModal: (active) => dispatch(locationOperations.toggleChangeNoLocationModal(active)),
  getMenuItemPegPoints: (productId) => dispatch(rewardsOperations.handleGetMenuItemPegPoints(productId)),
  resetSaveForEdit: () => dispatch(resetSaveForEdit()),
});

const FullList = (props) => {
  const [toShow, setToShow] = useState(1);
  const {toRender = [], layout} = props

  const showButton = toRender.length > 0

  const clickViewMore = () => {
    try {
      const data = {
        gtm: {
          uniqueEventId: '79',
          elementId: 'eatMenuCard-viewMore'
        },
        event: 'gtm.click'
      }
      gaTracker(data);
    } catch(e) {
      console.log(e, "clickViewMoreErr")
    }
    setToShow(toShow+1)
  }

  return (
    <div>
      {toRender.slice(0, toShow).map(category => {
        return <SingleCategory  key={toShow} {...props} toRender={category}/>
      })}
      { (showButton || !(toRender.length === toShow)) &&
        <Wrapper layout={layout}>
          <button className="cb-button-primary d-block pb-16 m-auto" id="eatMenuCard-viewMore" onClick={clickViewMore}>View More</button>
        </Wrapper>
      }
    </div>

  )
}

const SingleCategory = (props) => {
  const {layout, addToOrder, noLocation, saveDefault, toRender, location} = props
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);
  const childCategoriesPresent = Boolean(toRender.Categories.length)
  const [categoriesCards, setCategoriesCards] = useState([])
  const [noCategoryCards, setNoCategoryCards] = useState([])
  const [getPointsState, setGetPointsState] = useState(null)
  const [wishListProductsState, setWishListProductsState] = useState([])

  const handleNoCategories = (name, products, layout, addToOrder, saveDefault, description) => {
    return (
      <TextWList
        {...props}
        title={name}
        cards={noCategoryCards}
        layout={layout}
        addToOrder={addToOrder}
        noLocation={noLocation}
        saveDefault={saveDefault}
        //description={description}
        dogExists={true}/>
    )
  }

  useEffect(() => {
    const wishListProducts = Array.isArray(props.wishlist?.wishlistItems?.Products)
      ? props.wishlist.wishlistItems.Products
      : [];

    if (getPointsState === toRender.Name) {

      let identical = true;
      if (wishListProductsState.length === wishListProducts.length) {
        for (let i = 0; i < wishListProductsState.length; i++) {
          const json1 = JSON.stringify(wishListProductsState[i]);
          const json2 = JSON.stringify(wishListProducts[i]);

          if (json1 !== json2) {
            identical = false;
          }
        }
      } else {
        identical = false;
      }

      if (identical) {
        return
      }
    }

    if (typeof props.resetSaveForEdit === 'function') {
      props.resetSaveForEdit()
    }

    (async () => {
      if (childCategoriesPresent) {
        let allProductChainsIds
        let allProductsPoints = []

        toRender.Categories.forEach((category, index) => {
          if (Array.isArray(category.Products) && category.Products.length > 0) {
            const productChainsIds = category.Products.map((product) => {
              if (product.ProductChainId && product.IsEat) {
                return product.ProductChainId
              }
            }).filter((id) => id) // remove empty ids

            if (productChainsIds.length > 0) {
              if (!allProductChainsIds) {
                allProductChainsIds = productChainsIds.join(',')
              } else {
                allProductChainsIds += ',' + productChainsIds.join(',')
              }
            }
          }
        })

        if (allProductChainsIds?.length) {
          try {
            allProductsPoints = await props.getMenuItemPegPoints(allProductChainsIds)
          } catch (e) {
            console.error('Error getting products peg points', e.message)
            allProductsPoints = []
          }
        }

        toRender.Categories.forEach((category, index) => {
          if (Array.isArray(category.Products) && category.Products.length > 0) {
            let categoryCardsAux = category.Products.map(product => {
              return { fields: mapProduct(product, location, allProductsPoints.length ? allProductsPoints : null, wishListProducts) }
            })
            setCategoriesCards(prevState => {
              return {
                ...prevState,
                [`${category.SitecoreId}`]: categoryCardsAux,
              }
            })
          } else {
            let categoryCardsAux = category.Products.map(product => {
              return { fields: mapProduct(product, location, [], wishListProducts) }
            })
            setCategoriesCards(prevState => {
              return {
                ...prevState,
                [`${category.SitecoreId}`]: categoryCardsAux,
              }
            })
          }
        })
      } else {
        const productsNoCategory = toRender.Products
        if (Array.isArray(productsNoCategory) && productsNoCategory.length > 0) {
          const productChainsIds = productsNoCategory.map((product) => {
            if (product.ProductChainId && product.IsEat) {
              return product.ProductChainId
            }
          }).filter((id) => id) // remove empty ids

          let categoryCardsAux = productsNoCategory.map(product => {
            return { fields: mapProduct(product, location, [], wishListProducts) }
          })

          if (productChainsIds.length > 0) {
            props.getMenuItemPegPoints(productChainsIds.join(',')).then((response) => {
              categoryCardsAux = productsNoCategory.map(product => {
                return { fields: mapProduct(product, location, response.length ? response : null, wishListProducts) }
              })
              setNoCategoryCards(categoryCardsAux)
            }).catch(e => {
              console.error('error', e.message)
              setNoCategoryCards(categoryCardsAux)
            })
          } else {
            setNoCategoryCards(categoryCardsAux)
          }
        }
      }
      setGetPointsState(toRender.Name)
      setWishListProductsState(wishListProducts)
    })()
  }, [toRender.Categories, props.wishlist?.wishlistItems?.Products])


  useEffect(() => {
    if(!hasLoadedOnce){
      setHasLoadedOnce(true);
    }
  }, [])

  return (
    <React.Fragment>
      {(childCategoriesPresent) ? toRender.Categories.map((category, index) => {
        let Products = category.Products;
        if(!Array.isArray(category.Products)) {
          Products = []
        }
        if (Products?.length > 0 && !hasLoadedOnce) {
          impressionsGA(Products);
        }
        return (
        <TextWList
          title={{value: category.DisplayName}}
          description={category.Description}
          cards={categoriesCards[`${category.SitecoreId}`] || []}
          layout={layout}
          key={index}
          addToOrder={addToOrder}
          noLocation={noLocation}
          categoryLink={category.CategoryContentPagePath}
          saveDefault={saveDefault}
          {...props}
        />
      )}) : handleNoCategories({value: toRender.DisplayName}, toRender.Products, layout, addToOrder, saveDefault, toRender.Description)
         }
    </React.Fragment>
  )
}


export default connect(mapStateToProps, mapDispatchToProps)(EatCards);
