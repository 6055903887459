import React from 'react';
import {MealsandGifts, Wrapper, Container } from 'cb-design-system'

/**
 * Demonstrates usage of a Content List field type within JSS.
 * Content Lists are references to 0..n other content items.
 * In Sitecore terms, this maps by default to a Treelist field.
 */
const MealsandGiftsForm = (props) => {
  const model = {
    emailSendTo: props.fields?.EmailSendTo?.value || 'catering@crackerbarrel.com'
  }

  return (
    <Wrapper>
      <Container>
      <MealsandGifts
      model={model}
      />
      </Container>
      </Wrapper>
  );
};

export default MealsandGiftsForm;
