import React from 'react';
import { ReturnsView as Design } from 'cb-design-system';
import { ReactReduxContext } from 'react-redux';
import { AzureADClient, authProviderClient, AuthenticationState } from '../../AuthComponent';

const ReturnsView = () => {
  return (
    <ReactReduxContext.Consumer>
      {((context) => {
        return (
          <Design store={context.store} />            
        )}
        ).bind(this)
      }
    </ReactReduxContext.Consumer>
  );
    
}

export default ReturnsView;
