import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ContactUs, Wrapper, Container } from 'cb-design-system';
import { connect, ReactReduxContext } from 'react-redux';

import { ducks } from '../../ducks';

const locationOperations = ducks.location.operations

class ContactUsForm extends Component {

  constructor(props) {
    super(props);

    this.handleLocationSuccess = this.handleLocationSuccess.bind(this);
    this.handleLocationError = this.handleLocationError.bind(this);
  }

  componentDidMount() {
    if (!this.props.latLon) {
      // this.props.changeLatLon(this.props.latLon);
    }

    // TODO: Store location in cookie to avoid repeated lookups
    // this.props.getLocationByGps();
  }

  componentWillReceiveProps(newProps){
    if(newProps.latLon !== this.props.latLon){
      // this.props.changeLatLon(newProps.latLon);
      // this.props.getLocationByGps();
    }
  }

  handleLocationSuccess(position) {
    if(typeof position.coords === 'object') {
      // this.props.changeLatLon(position.coords);
      // this.props.getLocationByGps();
    }
  }

  handleLocationError(err) {
    this.props.handleError(err)
  }

  render() {
    return(
      <ReactReduxContext.Consumer>
        {((context) => (
          <Wrapper>
       <Container>
       <ContactUs
         store={context.store}
         results={this.props.results}
         isFetching={this.props.isFetching}
         isError={this.props.isError}
         err={this.props.err}
         onSuccess={this.handleLocationSuccess}
       />

     </Container>
     </Wrapper>
        )).bind(this)
      }
      </ReactReduxContext.Consumer>
    );
  }
};

ContactUsForm.propTypes = {
  latLon: PropTypes.object.isRequired,
  getLocationByIp: PropTypes.func.isRequired,
  getLocationByGps: PropTypes.func.isRequired,
  disclaimer: PropTypes.string
}

const mapStateToProps = (state) => {
  return {
    latLon: state.location.latLon,
    isFetching: state.location.isFetchingLocation,
    isError: state.location.isError,
    isLoading: state.waitlist.isLoading,
    results: state.location.locationResults,
    err: state.location.err
  };
}

const mapDispatchToProps = (dispatch) => ({
  changeLatLon: text => dispatch(locationOperations.changeLatLon(text)),
  getLocationByIp: () => dispatch(locationOperations.getLocationByIp()),
  getLocationByGps: () => dispatch(locationOperations.getLocationByGps()),
  handleError: err => dispatch(locationOperations.receiveError(err)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactUsForm);
