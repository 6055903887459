import React from 'react';
import { OfflineOrderDetails as OrderDetails } from 'cb-design-system';
import { connect, ReactReduxContext } from 'react-redux';
import { ducks } from "../../ducks";

const { offline: offlineDuck } = ducks;
const { operations } = offlineDuck;

const OfflineOrderDetails = (props) => {
            return (

                <OrderDetails
                    {...props}
                />
            )
}

const mapStateToProps = (state) => {

    return {
    isLoading: state.offline.isLoading,
    selectedOrder: state.offline.selectedOrder
    };
}

const mapDispatchToProps = (dispatch) => ({
    //getCart: () => (dispatch(operations.getCart())),
});

export default connect(mapStateToProps, mapDispatchToProps)(OfflineOrderDetails);