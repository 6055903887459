import React from 'react';
import { LocationDetail as DsLocationDetail } from 'cb-design-system';
import { connect, ReactReduxContext } from 'react-redux';
import {
  ducks
} from '../../ducks';

const locationOperations =  ducks.location.operations
const waitlistOperations =  ducks.waitlist.operations

const LocationDetail = (props) => {
  const { fields } = props
  return (
    <ReactReduxContext.Consumer>
    {((context) => {
      return (
        <DsLocationDetail
          store={context.store}
          fields={fields}
          {...props}
        />
        )
      }
      ).bind(this)
      }
    </ReactReduxContext.Consumer>
  );
};

const mapStateToProps = (state) => {
  return {
    results: state.location.results,
    isError: state.location.isError, 
    showForm: state.waitlist.showForm,
    location: state.location.location,
    waitlistConfirmation: state.waitlist.confirmation,
    waitlistLoading: state.waitlist.isLoading,
    waitlistActive: state.waitlist.waitlistActive,
  }
}

const mapDispatchToProps = (dispatch) => ({
  setLocation: (location, retaincart) => dispatch(locationOperations.setLocation(location, retaincart)),
  toggleWaitlist: () => dispatch(waitlistOperations.toggleWaitlist()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationDetail);
