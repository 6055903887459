/* eslint no-use-before-define: 0 */  // --> OFF
/* eslint no-undef: 0 */  // --> OFF

import React, { useEffect } from 'react';
import { Placeholder, VisitorIdentification, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
// import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Cookies, useCookies } from 'react-cookie';
import { dataApi } from '@sitecore-jss/sitecore-jss-react';
import LoadScript from 'react-load-script';

import { dataFetcher } from './dataFetcher';
import config from './temp/config';

import { useSelector } from 'react-redux';

import {
  Background,
  ScrollToTop,
  apiUtil,
  useGTMDispatch,
  pageLoadStarted,
  detectUser,
  pageView,
  useReadyStateEffect,
} from 'cb-design-system';
import { useLocation } from 'react-router-dom';

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

const getBuildInfo = () => ({
  commitHash: process.env.REACT_APP_GIT_COMMIT,
  branchName: process.env.REACT_APP_GIT_BRANCH,
  buildVersion: process.env.REACT_APP_BUILD_VERSION,
  environment: process.env.REACT_APP_ENVIRONMENT,
  buildDate: process.env.REACT_APP_BUILD_DATE,
});
if (typeof window !== 'undefined') { 
  window.getBuildInfo = getBuildInfo;
}


const Layout = (props) => {

  const {
    route,
    sitecoreContext
  } = props;

  const background = (route.fields && route.fields.PageBackground && route.fields.PageBackground.value) || '';

  const isEditing = sitecoreContext && sitecoreContext.pageEditing;
 
  const isProdUrl = window.location.host == "www.crackerbarrel.com";

  const canonicalUrl =
    (route.fields &&
      route.fields.CanonicalUrl &&
      route.fields.CanonicalUrl.value) ||
    '';
  const noFollowIndex =
    (route.fields &&
      route.fields.NoFollowIndex &&
      route.fields.NoFollowIndex.value) ||
    false;

  const defaultUrl = (typeof window !== 'undefined') ? window.location.href : '';

  const [cookies, setCookie] = useCookies(['scInit']);

  const sendDataToGTM = useGTMDispatch();
  const location = useLocation();
  const windowLocationPath = window.location.pathname; //We need both because there is a place where window.history.pushState is used
  const reduxStorage = useSelector((state) => {
    return {
      accountIdentifier: state.account?.aadResponse?.account?.accountIdentifier,
    };
  });
  const sessionStore = sessionStorage.getItem('persist:cb-ds')
    ? JSON.parse(JSON.parse(sessionStorage.getItem('persist:cb-ds'))?.account)
        ?.aadResponse?.account
    : undefined;

  const isUserLoggin = sessionStorage.getItem('persist:cb-ds')
    ? JSON.parse(JSON.parse(sessionStorage.getItem('persist:cb-ds'))?.account)
        ?.loggedin
    : undefined;

  const loyalty = sessionStorage.getItem('persist:cb-ds')
    ? JSON.parse(JSON.parse(sessionStorage.getItem('persist:cb-ds'))?.loyalty)
    ?.account
    : undefined; 

  const { accountIdentifier } = sessionStore
    ? sessionStore
    : reduxStorage;

  useEffect(() => {
    sendDataToGTM(pageLoadStarted({ route }));
  }, [windowLocationPath, location]);

  useEffect(() => {
    sendDataToGTM(detectUser({ accountIdentifier, isUserLoggin, loyalty }));
  }, [windowLocationPath, location]);

  useReadyStateEffect(() => {
    sendDataToGTM(pageView);
  }, [windowLocationPath, location]);

  const fetchOptions = {
    layoutServiceConfig: {
      host: apiUtil.API.baseUrl
    },
    querystringParams: {
      sc_lang: 'en',
      sc_apikey: config.sitecoreApiKey
    },
    fetcher: dataFetcher,
  };

  // Cookie has not been set. Fire layout service
  if (!cookies?.scInit) {
    dataApi.fetchRouteData('/', fetchOptions)
      .catch((error) => {
        if (error.response && error.response.status === 404 && error.response.data) {
          return error.response.data;
        }
        return null;
      });

    setCookie('scInit', '1', { path: '/' });
  }

  useEffect(() => {
    window.getBuildInfo = getBuildInfo;
    const cookieScript = document.createElement('script');
    cookieScript.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";  
    cookieScript.type = "text/javascript"; 
    cookieScript.charset = "UTF-8";

    const wrapperScript = document.createElement('script');
    wrapperScript.type="text/javascript";
    wrapperScript.text = 'function OptanonWrapper() { }'

    if (isProdUrl){
      cookieScript.setAttribute('data-domain-script', 'c5b0f1f2-9186-40c1-b19b-4fcfff9cd472');
    }
    else {
      cookieScript.setAttribute('data-domain-script', 'c5b0f1f2-9186-40c1-b19b-4fcfff9cd472-test');
    }
    // get the top level script 
    var a = document.getElementsByTagName('script')[0];   // [2] is the index of library.js
    var parentT = a.parentNode;
   
    // add scripts before other scripts in Head
    parentT.insertBefore(cookieScript , a);
    parentT.insertBefore(wrapperScript , a);
  },[])
 
  return (
    <React.Fragment>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <Helmet>
        <title>
          {(route.fields && route.fields.MetaTitle && route.fields.MetaTitle.value) || 'Cracker Barrel | Page'}
        </title>
        <meta name="description" content={(route.fields && route.fields.MetaDescription && route.fields.MetaDescription.value) || ''} />
        <meta name="keywords" content={(route.fields && route.fields.MetaKeywords && route.fields.MetaKeywords.value) || ''} />
        <meta name="author" content={(route.fields && route.fields.MetaAuthor && route.fields.MetaAuthor.value) || ''} />

        <meta name="robots" content={noFollowIndex ? 'noindex, nofollow' : 'index, follow'} />
        <meta name="facebook-domain-verification" content="p1hrs9x1n6eagavdn2rfww80g194ac" />
        <link rel="canonical" href={canonicalUrl !== '' ? canonicalUrl : defaultUrl} />        


        {/* <link rel="icon" type="image/png" href={Favicon} sizes="16x16" /> */}
      </Helmet>

      {/*
        VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
        If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
        For XM (CMS-only) apps, this should be removed.

        VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
      */}
      <VisitorIdentification />


      {/* root placeholder for the app, which we add components to using route data */}
      <div className="cb-bg-soft-yellow">

        {!isEditing && <Background background={background} />}

        <header className=''>
          <Placeholder
            name='jss-header'
            rendering={route}
            isCnbSite={false}
            isStorePortal={false}
          />
            
          {/* Global site tag (gtag.js) - Google Marketing Platform */}
          {/* TAKE OUT TO CLEAN UP GAU */}
          
          <script async src="https://www.googletagmanager.com/gtag/js?id=DC-9289446"></script>
          <script>{`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments)}
            gtag('js', new Date());

            gtag('config', 'DC-9289446');
            `}
          </script>
          {/* End of global snippet: Please do not remove */}

          {/*-- BEGIN ControlTag for "Cracker Barrel Old Country Store, Inc." -->*/}
          <script class="kxct" data-id="ukyr35vqd" data-timing="async" data-version="3.0" type="text/javascript">
            {` window.Krux||((Krux=function(){Krux.q.push(arguments)}).q=[]);
            (function(){
              var k=document.createElement('script');k.type='text/javascript';k.async=true;
              k.src=(location.protocol==='https:'?'https:':'http:')+'//cdn.krxd.net/controltag/ukyr35vqd.js';
              var s=document.getElementsByTagName('script')[0];s.parentNode.insertBefore(k,s);
            }()); `}
          </script>
          {/*END ControlTag*/}

        </header>

        <main role="main" className="position-relative">
          <ScrollToTop />
          <Placeholder name="jss-content" rendering={route} isCnbSite={false} />
        </main>

        <footer className="cb-footer__wrapper">
          <Placeholder name="jss-footer" rendering={route} isCnbSite={false} />
        </footer>
      </div>


    </React.Fragment>
  )
};

export default withSitecoreContext()(Layout);
