/* eslint no-use-before-define: 0 */  // --> OFF
/* eslint no-undef: 0 */  // --> OFF

import React, { useEffect } from 'react';
import { Placeholder, VisitorIdentification, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
// import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Cookies, useCookies } from 'react-cookie';
import { dataApi } from '@sitecore-jss/sitecore-jss-react';
import LoadScript from 'react-load-script';

import { dataFetcher } from './dataFetcher';
import config from './temp/config';

import {
  Background,
  ScrollToTop,
  apiUtil,
  useGTMDispatch,
  pageLoadStarted
} from "cb-design-system";

const Layout = (props) => {

  const {
    sitename,
    route,
    sitecoreContext
  } = props;

  const background = (route.fields && route.fields.PageBackground && route.fields.PageBackground.value) || '';

  const isEditing = sitecoreContext && sitecoreContext.pageEditing;

  const canonicalUrl = (route.fields && route.fields.CanonicalUrl && route.fields.CanonicalUrl.value) || '';
  const noFollowIndex = (route.fields && route.fields.NoFollowIndex && route.fields.NoFollowIndex.value) || false;

  const defaultUrl = (typeof window !== 'undefined') ? window.location.href : '';

  const [cookies, setCookie] = useCookies(['scInit']);

  const sendDataToGTM = useGTMDispatch();

  useEffect(() => {
    sendDataToGTM(pageLoadStarted({route}));
  }, []);

  const fetchOptions = {
    layoutServiceConfig: {
      host: apiUtil.API.baseUrl
    },
    querystringParams: {
      sc_lang: 'en',
      sc_apikey: config.sitecoreApiKey
    },
    fetcher: dataFetcher,
  };

  // Cookie has not been set. Fire layout service
  if (!cookies?.scInit) {
    dataApi.fetchRouteData('/', fetchOptions)
      .catch((error) => {
        if (error.response && error.response.status === 404 && error.response.data) {
          return error.response.data;
        }
        return null;
      });

    setCookie('scInit', '1', { path: '/' });
  }



  return (
    <React.Fragment>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <Helmet>
        <title>
          {(route.fields && route.fields.MetaTitle && route.fields.MetaTitle.value) || "Chicken N' Biscuit's | Page"}
        </title>
        <meta name="description" content={(route.fields && route.fields.MetaDescription && route.fields.MetaDescription.value) || ''} />
        <meta name="keywords" content={(route.fields && route.fields.MetaKeywords && route.fields.MetaKeywords.value) || ''} />
        <meta name="author" content={(route.fields && route.fields.MetaAuthor && route.fields.MetaAuthor.value) || ''} />

        <meta name="robots" content={noFollowIndex ? 'noindex, nofollow' : 'index, follow'} />
        <meta name="facebook-domain-verification" content="p1hrs9x1n6eagavdn2rfww80g194ac" />
        <link rel="canonical" href={canonicalUrl !== '' ? canonicalUrl : defaultUrl} />        

        {/* <link rel="icon" type="image/png" href={Favicon} sizes="16x16" /> */}
      </Helmet>

      {/*
        VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
        If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
        For XM (CMS-only) apps, this should be removed.

        VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
      */}
      <VisitorIdentification />


      {/* root placeholder for the app, which we add components to using route data */}


      <div className="cb-bg-linen">

        {!isEditing && <Background background={background} />}

        <header className="">
          <Placeholder name="jss-header" rendering={route} isCnbSite={true} isStorePortal={false} />
          {/* {<!-- Global site tag (gtag.js) - Google Analytics -->} */}
          {/* TAKE OUT TO CLEAN UP GAU */}
          <script async src="https://www.googletagmanager.com/gtag/js?id=UA-167694515-4"></script>
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'UA-167694515-4');
            `}
          </script>
          {/* End of global snippet: Please do not remove */}


          {/*-- BEGIN ControlTag for "Cracker Barrel Old Country Store, Inc." -->*/}
          <script class="kxct" data-id="v74cv8d2v" data-timing="async" data-version="3.0" type="text/javascript">
            {` window.Krux||((Krux=function(){Krux.q.push(arguments)}).q=[]);
            (function(){
            var k=document.createElement('script');k.type='text/javascript';k.async=true;
            k.src=(location.protocol==='https:'?'https:':'http:')+'//cdn.krxd.net/controltag/v74cv8d2v.js';
            var s=document.getElementsByTagName('script')[0];s.parentNode.insertBefore(k,s);
            }());`}
          </script>
          {/*END ControlTag*/}


        </header>

        <main role="main" className="position-relative">
          <ScrollToTop />
          <Placeholder name="jss-content" rendering={route} isCnbSite={true} />
        </main>

        <footer className="cb-footer__wrapper">
          <Placeholder name="jss-footer" rendering={route} isCnbSite={true} />
        </footer>
      </div>


    </React.Fragment>
  )
};

export default withSitecoreContext()(Layout);
