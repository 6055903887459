import React, { Fragment } from "react";
import { JoinRewardsPromo } from "cb-design-system";
import { ReactReduxContext } from "react-redux";
import { authProviderClient, AzureADClient } from "../../AuthComponent";

const JoinRewardsModule = (props) => {
  return (
    <Fragment>
      <ReactReduxContext.Consumer>
        {((context) => {
          return (
            <AzureADClient
              forceLogin={false}
              provider={authProviderClient}
              reduxStore={context.store}
            >
              {({ authenticationState }) => {
                return (
                  <JoinRewardsPromo
                    {...props}
                    authenticationState={authenticationState}
                  />
                );
              }}
            </AzureADClient>
          );
        }).bind(this)}
      </ReactReduxContext.Consumer>
    </Fragment>
  );
};

export default JoinRewardsModule;
