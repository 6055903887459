import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { authProvider } from './AuthProvider';
import DummyPersist from './components/Utils/DummyPersist';

/* Set Authentication */
let runtime = process.env.RUNTIME;
let AzureADClient = AzureAD;
let authProviderClient = authProvider;

if (typeof window === 'undefined') {
  AzureADClient = DummyPersist;
  authProviderClient = {};
}

export {
  AzureADClient,
  authProviderClient,
  AuthenticationState
}
