import React from 'react';
import { ContentBlock } from 'cb-design-system';
import moment from 'moment';

const TitleText = (props) => {

  const {
    title = {},
    titleTag = {},
    subtitle = {},
    text = {},
    publishedDate = {},
    TextAlignment = {}, // left, center, right
    Background = {}, // default, solid
    TopPadding = {}, 
    BottomPadding = {},
  } = props?.fields;

  const PublishedDate = { 
    value: (publishedDate?.value) ? moment(publishedDate.value).format('MM/DD/yyyy') : ""
  }

  const model = {
    title: title,
    titleTag: titleTag.value,
    subtitle: Boolean(subtitle?.value) ? subtitle : PublishedDate,
    text: text
  }

  const layout = {
    background: Background.value || 'solid',
    topPadding: TopPadding?.fields?.key?.value || 'medium',
    bottomPadding: BottomPadding?.fields?.key?.value || 'medium',
  }

  return (
    <ContentBlock model={model} alignment={TextAlignment.value} layout={layout} />
  )
};

export default TitleText;
