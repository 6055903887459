import React from 'react';
import { connect, ReactReduxContext } from 'react-redux';
import { LocationSelection as DsLocationSelection } from 'cb-design-system';

const LocationSelection = (props) => {

  const {
    LocationSelectionMessage = {value: ""},
    SelectedLocationMessage = {value: ""},
    HideWaitlist = {value: ""},
    TextAlignment = {}, // left, center, right
    Background = {}, // default, solid
    TopPadding = {},
    BottomPadding = {}
  } = props?.fields || {};

  const layout = {
    background: Background?.value || 'solid',
    topPadding: TopPadding?.fields?.key.value || 'medium',
    bottomPadding: BottomPadding?.fields?.key.value || 'medium',
    alignment: TextAlignment?.value || 'center'
  }

  return (
    <>
    <ReactReduxContext.Consumer>
      {((context) => (
        <DsLocationSelection
          store={context.store}
          layout={layout}
          locationMessage={LocationSelectionMessage}
          selectedMessage={SelectedLocationMessage}
          hideWaitlist={HideWaitlist}
        />
      )).bind(this)
    }
      </ReactReduxContext.Consumer>
    </>
  )
};

export default LocationSelection;
