import React from 'react';
import { OfflineCheckout as OffCheckout } from 'cb-design-system';
import { connect, ReactReduxContext } from 'react-redux';
import { ducks } from "../../ducks";


const { offline: offlineDuck } = ducks;
const { operations } = offlineDuck;

const OfflineCheckout = (props) => {
            return (

                <OffCheckout
                    {...props}
                />
            )
}

const mapStateToProps = (state) => {
  return {
    cart : state.offline.cart,
    cost: state.offline.price,
    isLoading: state.offline.isLoading,
    isRetail: state.offline.isRetail,
    destructuredCart: state.offline.destructuredCart,
    fullCart: state.offline,
    shippingModes: state.offline.shippingModes || [],
    shippingMode: state.offline.shippingMode,
    isSendingPromoCode: state.offline.isSendingPromoCode,
    isSubmittingCart: state.offline.isSubmittingCart,
    checkoutSuccess: state.offline.checkoutSuccess,
    submitInfo: state.offline.submitInfo,
    validateSuccess: state.offline.validateSuccess,
    customerList: state.offline.customerList,
    assigned: state.offline.assigned,
    savedPromos: state.offline.savedPromos || [],
    isSendingPromoCode: state.offline.isSendingPromoCode,
    isSubmittingCart: state.offline.isSubmittingCart,
  };
}

const mapDispatchToProps = (dispatch) => ({

  setRetail: (addressInfo, deliveryMode) => dispatch(operations.postRetailOrder(addressInfo, deliveryMode)),
  handleBackToCart: (e) => {

    const elem = e.target;
    const processing = elem.getAttribute('processing');

    if(!processing) {

      e.preventDefault();
      elem.setAttribute('processing', true);
      elem.click();
    }

    return true;
  },
  
  validateHandler: () => dispatch(operations.validateOrder()),
  hideValidateCart: () => dispatch(operations.hideValidateCart()),
  submitHandler: (payload) => dispatch(operations.submitOrder(payload)),
  setTip: (amt, percent) => dispatch(operations.postTip(amt, percent)),  
  changeCheckoutSuccess: () => dispatch(operations.closeCheckout()),
  resetGoToCheckout: () => dispatch(operations.resetGoToCheckout()),
  postPromoCode: promo => dispatch(operations.setPromoCode(promo)),
  deletePromoCode: promo => dispatch(operations.deletePromoCode(promo)),

});

export default connect(mapStateToProps, mapDispatchToProps)(OfflineCheckout);