import React from 'react';
import { RetailLeftRail  } from 'cb-design-system';

const RetailSubCategoryNavLeftRail = (props) => {
  //Eat nav similar
  console.log(props, "RetailSubCategoryNavLeftRail")
  return (
    <RetailLeftRail {...props}/>
  );
};

export default RetailSubCategoryNavLeftRail;


