/* eslint no-use-before-define: 0 */  // --> OFF
/* eslint no-undef: 0 */  // --> OFF

import React, { useEffect } from 'react';
import { Placeholder, VisitorIdentification, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
// import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Cookies, useCookies } from 'react-cookie';
import { dataApi } from '@sitecore-jss/sitecore-jss-react';
import LoadScript from 'react-load-script';

import { dataFetcher } from './dataFetcher';
import config from './temp/config';

import { useSelector } from 'react-redux';

import {
  Background,
  ScrollToTop,
  apiUtil,
  useGTMDispatch,
  pageLoadStarted,
  detectUser,
  pageView,
  useReadyStateEffect
} from 'cb-design-system';
import { useLocation } from 'react-router-dom';

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

const FoodRefundLayout = (props) => {

  const {
    route,
    sitecoreContext
  } = props;

  const background = (route.fields && route.fields.PageBackground && route.fields.PageBackground.value) || '';

  const isEditing = sitecoreContext && sitecoreContext.pageEditing;

  const canonicalUrl = (route.fields && route.fields.CanonicalUrl && route.fields.CanonicalUrl.value) || '';
  const noFollowIndex = (route.fields && route.fields.NoFollowIndex && route.fields.NoFollowIndex.value) || false;

  const defaultUrl = (typeof window !== 'undefined') ? window.location.href : '';

  const [cookies, setCookie] = useCookies(['scInit']);

  const sendDataToGTM = useGTMDispatch();
  const location = useLocation();
  const windowLocationPath = window.location.pathname; //We need both because there is a place where window.history.pushState is used
  const reduxStorage = useSelector((state) => {
    return {
      accountIdentifier: state.account?.aadResponse?.account?.accountIdentifier,
    };
  });
  const sessionStore = sessionStorage.getItem('persist:cb-ds')
    ? JSON.parse(JSON.parse(sessionStorage.getItem('persist:cb-ds'))?.account)
        ?.aadResponse?.account
    : undefined;

  const isUserLoggin = sessionStorage.getItem('persist:cb-ds')
    ? JSON.parse(JSON.parse(sessionStorage.getItem('persist:cb-ds'))?.account)
        ?.loggedin
    : undefined;

  const loyalty = sessionStorage.getItem('persist:cb-ds')
    ? JSON.parse(JSON.parse(sessionStorage.getItem('persist:cb-ds'))?.loyalty)
    ?.account
    : undefined; 

  const { accountIdentifier } = sessionStore
    ? sessionStore
    : reduxStorage;

  useEffect(() => {
    sendDataToGTM(pageLoadStarted({ route }));
  }, [windowLocationPath, location]);

  useEffect(() => {
    sendDataToGTM(detectUser({ accountIdentifier, isUserLoggin, loyalty }));
  }, [windowLocationPath, location]);

  useReadyStateEffect(() => {
    sendDataToGTM(pageView);
  }, [windowLocationPath, location]);

  const fetchOptions = {
    layoutServiceConfig: {
      host: apiUtil.API.baseUrl
    },
    querystringParams: {
      sc_lang: 'en',
      sc_apikey: config.sitecoreApiKey
    },
    fetcher: dataFetcher,
  };

  // Cookie has not been set. Fire layout service
  if (!cookies?.scInit) {
    dataApi.fetchRouteData('/', fetchOptions)
      .catch((error) => {
        if (error.response && error.response.status === 404 && error.response.data) {
          return error.response.data;
        }
        return null;
      });

    setCookie('scInit', '1', { path: '/' });
  }

  return (
    <React.Fragment>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <Helmet>
        <title>
          {(route.fields && route.fields.MetaTitle && route.fields.MetaTitle.value) || 'Refund Portal | Page'}
        </title>
        <meta name="description" content={(route.fields && route.fields.MetaDescription && route.fields.MetaDescription.value) || ''} />
        <meta name="keywords" content={(route.fields && route.fields.MetaKeywords && route.fields.MetaKeywords.value) || ''} />
        <meta name="author" content={(route.fields && route.fields.MetaAuthor && route.fields.MetaAuthor.value) || ''} />

        <meta name="robots" content={noFollowIndex ? 'noindex, nofollow' : 'index, follow'} />
        <link rel="canonical" href={canonicalUrl !== '' ? canonicalUrl : defaultUrl} />        


        {/* <link rel="icon" type="image/png" href={Favicon} sizes="16x16" /> */}
      </Helmet>

      {/*
        VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
        If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
        For XM (CMS-only) apps, this should be removed.

        VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
      */}
      <VisitorIdentification />


      {/* root placeholder for the app, which we add components to using route data */}
      <div className="cb-bg-linen">

        {!isEditing && <Background background={background} />}

        <header className="">
          <Placeholder name="jss-header" rendering={route} isStorePortal={true} />

          {/* Global site tag (gtag.js) - Google Marketing Platform */}
          {/* TAKE OUT TO CLEAN UP GAU */}
          <script async src="https://www.googletagmanager.com/gtag/js?id=DC-9289446"></script>
          <script>{`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments)}
            gtag('js', new Date());

            gtag('config', 'DC-9289446');
            `}
          </script>
          {/* End of global snippet: Please do not remove */}

          {/*Facebook Pixel Code*/}
          <script>
            {`
              !function(f,b,e,v,n,t,s)
              if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '842539642526393');
              fbq('track', 'PageView');
              </script>
              <noscript><img height="1" width="1" style="display:none"
              src="https://www.facebook.com/tr?id=842539642526393&ev=PageView&noscript=1"
              /></noscript>
            `}
          </script>
          {/*End Facebook Pixel Code*/}

          {/*Snap Pixel Code*/}
          <script type='text/javascript'>
            {`
              (function(e,t,n)if(e.snaptr)return;var a=e.snaptr=function()
              {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
              a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
              r.src=n;var u=t.getElementsByTagName(s)[0];
              u.parentNode.insertBefore(r,u);)(window,document,
              'https://sc-static.net/scevent.min.js');

              snaptr('init', '433123c9-3085-4bb6-8d66-af59b30e784c', {"<"}
              'user_email': '__INSERT_USER_EMAIL__'
              {">"});

              snaptr('track', 'PAGE_VIEW');
            `}
          </script>
          {/*End Snap Pixel Code*/}

          {/*Pinterest Tag*/}
          <script>
            {`
              !function(e)if(!window.pintrk){window.pintrk = function () {
              window.pintrk.queue.push(Array.prototype.slice.call(arguments))}};
              var n=window.pintrk;n.queue=[],n.version="3.0";
                var t=document.createElement("script");t.async=!0,t.src=e;
                var r=document.getElementsByTagName("script")[0];
                r.parentNode.insertBefore(t,r){("https://s.pinimg.com/ct/core.js")};
              pintrk('load', '2618472854571', {em}: '<user_email_address>');
              pintrk('page');</user_email_address>
              <noscript>
              <img height="1" width="1" style="display:none;" alt=""
                src="https://ct.pinterest.com/v3/?event=init&tid=2618472854571&pd[em]=<hashed_email_address>&noscript=1" />
              </noscript>
              {/*end Pinterest Tag*/}
            `}
          </script>

          {/*-- BEGIN ControlTag for "Cracker Barrel Old Country Store, Inc." -->*/}
          <script class="kxct" data-id="ukyr35vqd" data-timing="async" data-version="3.0" type="text/javascript">
            {` window.Krux||((Krux=function(){Krux.q.push(arguments)}).q=[]);
              (function(){
                var k=document.createElement('script');k.type='text/javascript';k.async=true;
                k.src=(location.protocol==='https:'?'https:':'http:')+'//cdn.krxd.net/controltag/ukyr35vqd.js';
                var s=document.getElementsByTagName('script')[0];s.parentNode.insertBefore(k,s);
              }());
            `}
          </script>
          {/*END ControlTag*/}

        </header>
        {/* <h2>BACKENDPORTAL LAYOUT</h2> */}
        <main role="main" className="position-relative">
          <ScrollToTop />
          <Placeholder name="jss-content" rendering={route} />
        </main>

        <footer className="cb-footer__wrapper">
          <Placeholder name="jss-footer" rendering={route} />
        </footer>
      </div>


    </React.Fragment>
  )
};

export default withSitecoreContext()(FoodRefundLayout);
