import React from 'react';
import { ReactReduxContext } from 'react-redux';
import { AzureADClient, authProviderClient, AuthenticationState } from '../../AuthComponent';

import { authConfig } from '../../AuthProvider';

import { Account as AccountPage } from 'cb-design-system';
import { Loading } from 'cb-design-system';


/**
 * Demonstrates usage of a Content List field type within JSS.
 * Content Lists are references to 0..n other content items.
 * In Sitecore terms, this maps by default to a Treelist field.
 */
const CustomerAccount = (props) => {
  // const {

  // } = props.fields;

  // const layout = {
  //   background: 'solid',
  //   topPadding: 'medium',
  //   bottomPadding: 'medium',
  // }

  const myAccountTabModel = props.fields;
  const redirect = () => {
    if (typeof window !== 'undefined') {
      window.location = '/'
    }
  }

  const handleProfileEdit = async (error) => {
    if(error)
    console.error("handleProfileEdit",error)
    const email = authProviderClient?.account?.idToken?.email || null;
    const authorityString = `${authConfig.domain}/${authConfig.mfaProfilePolicy}`
    console.log("handleProfileEdit", authorityString)

    try{
    await authProviderClient.setAuthenticationParameters ({
        authority: authorityString,
        loginHint: email
      })
      console.log("handleProfileEdit setAuthenticationParameters")

    await  authProviderClient.login()
    }catch(error){
       console.error(error)
    }
  }

  const handlePasswordReset = (error) => {
    const email = authProviderClient?.account?.idToken?.email || null;

    if (email) {
      authProviderClient.setAuthenticationParameters ({
        authority: `${authConfig.domain}/${authConfig.mfaPasswordPolicy}`,
        loginHint: email
      })
    } else {
      authProviderClient.setAuthenticationParameters ({
        authority: `${authConfig.domain}/${authConfig.mfaPasswordPolicy}`,
      })
    }

    authProviderClient.login()
  }

  return (
    <ReactReduxContext.Consumer>
      {((context) => {
        return (
          <AzureADClient provider={authProviderClient} reduxStore={context.store}>
            {
              ({login, logout, authenticationState, error, accountInfo}) => {
                  if( error != null){
                      console.error(error)
                  }
                switch (authenticationState) {
                  case AuthenticationState.Authenticated:
                    return (
                      <AccountPage
                        myAccountTabModel={myAccountTabModel}
                        store={context.store}
                        account={accountInfo}
                        logout={logout}
                        handleEditProfileClick={handleProfileEdit}
                        handlePasswordReset={handlePasswordReset}
                      />
                    )
                  case AuthenticationState.InProgress:
                    return (
                      <>
                        <Loading isLoading={true}/>
                      </>
                    )
                  default:
                    return (
                      <>
                      {redirect()}
                      </>
                    )
                }
              }
            }

          </AzureADClient>
        )}
        ).bind(this)
      }
    </ReactReduxContext.Consumer>
  );
};

export default CustomerAccount;

