import React from "react";
import { AnniversaryOverlay as DsAnniversaryOverlay } from "cb-design-system";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { ReactReduxContext } from "react-redux";
import { AzureADClient, authProviderClient } from "../../AuthComponent";

const AnniversaryOverlay = (props) => {
  const model = props.fields;

  return (
    <React.Fragment>
      <ReactReduxContext.Consumer>
        {((context) => {
          return (
            <AzureADClient
              forceLogin={false}
              provider={authProviderClient}
              reduxStore={context.store}
            >
              {({ authenticationState, error }) => {
                if (error != null) {
                  console.error(error);
                }

                return (
                  <DsAnniversaryOverlay
                    model={model}
                    authenticationState={authenticationState}
                  />
                );
              }}
            </AzureADClient>
          );
        }).bind(this)}
      </ReactReduxContext.Consumer>
    </React.Fragment>
  );
};

export default withSitecoreContext()(AnniversaryOverlay);
