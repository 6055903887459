import React from 'react';
import { RetailLanding } from 'cb-design-system';
import { connect } from 'react-redux';
import { ducks } from "../../ducks";
import { Preview } from "../EatMenuDetail"

const { retail: retailDuck, cart: cartDuck, wishlist: wishlistDuck } = ducks;

const { operations } = retailDuck
const { pageChange,
  termChange,
  setPageSize,
  setDataSource,
  sortByChange,
  facetChange } = operations

const { operations: cartOps } = cartDuck
const { postItem: duckPostCart } = cartOps
const handleWishlist = wishlistDuck.operations.handleWishlist

const RetailProductsCardsWithFacets = (props) => {
  return (
    <>
      <Preview/>
      <RetailLanding
        {...props}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    total : state.retail.totalRetail || 0,
    currentPage : state.retail.currentPage || 0,
    pageSize: state.retail.pageSize || 12,
    query: "",
    Products: state.retail.products || [],
    facets: state.retail.facets || [],
    results: state.retail.cards || [],
    pageCount: state.retail.pageCount,
    sortByOptions: state.retail.sortByOptions,
    retail : state.retail,
    loading: state.retail.isLoading
  };
}

const mapDispatchToProps = (dispatch) => ({
  pageChange: (newPage) => dispatch(pageChange(newPage)),
  termChange: () => dispatch(termChange(true)),
  modifierChange: (topic, isAdd, category, slug) => dispatch(facetChange(topic, isAdd, slug)),
  pageSizeSet: (pageSize) => dispatch(setPageSize(pageSize)),
  setDataSource: (dataSource) => dispatch(setDataSource(dataSource)),
  sortByChange: (dataSource) => dispatch(sortByChange(dataSource)),
  addToOrder: (postData) => dispatch(duckPostCart(postData)),
  handleFavorite: (productId, isAdd) => dispatch(handleWishlist(productId, isAdd))
});

export default connect(mapStateToProps, mapDispatchToProps)(RetailProductsCardsWithFacets);


