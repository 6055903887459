import React, {useState} from 'react';
import { Detail, Preview as DesignPreview, handleCost, handleModNames } from 'cb-design-system';
import { connect } from 'react-redux';
import { ducks } from "../../ducks";

const { cart: cartDuck, menu: menuDuck, error: errorDuck, rewards: rewardsDuck } = ducks;
const { operations } = cartDuck
const { operations: menuOperations } = menuDuck
const { postItem: duckPostCart, closeAddTo, patchLine } = operations
const { clearDefault } = menuOperations
const rewardsOperations = rewardsDuck.operations

const errorOperations = errorDuck.operations;
const receiveError = errorOperations.receiveError;

const ConnectedPreview = (props) => {
  const { newItem = {}, cart } = props
  const originalNewItem = cart?.length ? cart[cart.length - 1] : {};
  
  const value = handleCost([], originalNewItem.quantity, originalNewItem.LineCost);  
  const addOn = {
    name: originalNewItem?.AddOnProductDetails?.Name,
    cost: originalNewItem?.AddOnProductDetails?.LineCost
  };

  const items = [{
    title: newItem.displayName,
    modNames: handleModNames(newItem.options),
    cost: value,
    imgUrl: newItem.imageUrl,
    variantOptions: newItem.variantOptions,
    addOn
  }]

  return (<DesignPreview {...props} items={items}/>)
}

const previewMapStateToProps = (state) => {
  return {
    cart: state.cart.cart,
    addTo : state.cart.addedToCart,
    newItem: state.cart.newItem || {},
    upsellInfo: state.cart.upsellInfo || {},
    redeemedRewards: state.rewards.redeemedRewards || null,
  };
}

  // Web API is /sitecore/api/ProductDetail/{productId}
  // Example Web API /sitecore/api/ProductDetail/14549400

  const layout = {
    background: "default",
    topPadding: "medium",
    bottomPadding: "medium"
  }
const previewMapDispatchToProps = (dispatch) => ({
  close: () => dispatch(closeAddTo()),
});

export const Preview = connect(previewMapStateToProps, previewMapDispatchToProps)(ConnectedPreview);

const EatMenuDetail = (props) => {
  const [edit, setEdit] = useState(false)
  const {
    fields = {},
    updateCartLineRedux = () => console.log("updateCartLineRedux"),

  } = props

  const {
    Disclaimer = {}
  } = fields

  const overrideOptions = {
    defaultOptionName: props.fields?.defaultOptionName?.value || 'Buttermilk Biscuits'
  }

  const updateCartLine = (lineId, options, counter, lineItem, isDetail, addOn) => {
    updateCartLineRedux(lineId, options, counter, lineItem, isDetail, addOn);
    setEdit(true)
  }

  return (
    <React.Fragment>
      <Preview isEdit={edit}/>
      <Detail {...props} updateCartLine={updateCartLine} layout={layout} Disclaimer={Disclaimer} overrideOptions={overrideOptions} />
    </React.Fragment>
  )
}


const mapStateToProps = (state) => {
  return {
    defaultOptions : state.menu.defaultOptions,
    menu: state.menu,
    editCartItem: state.cart.saveForEdit,
    location: state.location?.location,
    addTo : state.cart.addedToCart,
    isLoading: state.cart.isLoading
  };
}
const mapDispatchToProps = (dispatch) => ({
  postItem: (postData) => dispatch(duckPostCart(postData)),
  clearDefault: () => dispatch(clearDefault()),
  updateCartLineRedux: (lineId, options, counter, lineItem, isDetail, addOn) => dispatch(patchLine(lineId, options, counter, lineItem, isDetail, addOn)),
  recieveError: (err) => dispatch(receiveError(err)),
  getMenuItemPegPoints: (productId) => dispatch(rewardsOperations.handleGetMenuItemPegPoints(productId))
});

export default connect(mapStateToProps, mapDispatchToProps)(EatMenuDetail);
