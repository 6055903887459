import React from 'react';
import { connect } from 'react-redux';
import { ArticleList as DesignArticleList } from 'cb-design-system'
import { ducks } from "../../ducks";

const { article } = ducks;

const { operations } = article
const { getArticles, setCategory, setYear, setDataSource, setPageSize } = operations

const ArticleList = (props) => {
  return (
    <DesignArticleList {...props}/>
  );
};

const mapStateToProps = (state) => {
  return {
    total : state.article.totalArticles || [],
    currentPage : state.article.currentPage,
    articleResults : state.article.articleResults || [],
    filterYear : state.article.filterYear || undefined,
    facets : state.article.facets
  };
}

const mapDispatchToProps = (dispatch) => ({
  getArticles: (newPage, year) => dispatch(getArticles(newPage, year)),
  setYear: (year) => dispatch(setYear(year)),
  setDataSource: (datasource) => dispatch(setDataSource(datasource)),
  setPageSize: (pageSize) => dispatch(setPageSize(pageSize))
});

export default connect(mapStateToProps, mapDispatchToProps)(ArticleList);


