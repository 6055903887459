import React from 'react';
import { OfflineCart } from 'cb-design-system';
import { connect, ReactReduxContext } from 'react-redux';
import { ducks } from "../../ducks";
import { AuthenticationState } from '../../AuthComponent';


const { offline: offlineDuck } = ducks;
const { operations } = offlineDuck;

const OffCart = (props) => {
            return (

                <OfflineCart
                    {...props}
                    authenticationState={AuthenticationState.Unauthenticated}
                />
            )
}

const mapStateToProps = (state) => {

    console.log('in offline cart',state);
    const shippingModes = state.offline.shippingModes || []
    return {
    cart : state.offline.cart,
    cost: state.offline.price,
    isLoading: state.offline.isLoading,
    //quantity: state.offline.quantity,
    isRetail: state.offline.isRetail,
    destructuredCart: state.offline.destructuredCart,
    shippingModes: shippingModes.map(el => el.name),
    shippingCosts: shippingModes.map(el => (el.cost) ? `$${el.cost.toFixed(2)}` : "Free"),
    shippingMode: state.offline.shippingMode,
    customerList: state.offline.customerList,
    assigned: state.offline.assigned
    };
}

const mapDispatchToProps = (dispatch) => ({
    getCart: () => (dispatch(operations.getCart())),
    getShippingModes: () => dispatch(operations.getShippingModes()),
    setShippingMode: (shippingMode) => dispatch(operations.setShippingMode(shippingMode)),
    deleteItem: (id) => dispatch(operations.deleteCartSend(id)),
    patchItem: (lineId, options, newQuantity) => dispatch(operations.updateCartLine(lineId, options, newQuantity)),
    getCusomerList: (emailId) => dispatch(operations.getCusomerList(emailId)),
    assignCustomer: (customer) => dispatch(operations.assignCustomer(customer)),
    resetCustomerList: () => dispatch(operations.resetCustomerList()),
    setLoaderAfterAssign: () => dispatch(operations.setLoaderAfterAssign())
});

export default connect(mapStateToProps, mapDispatchToProps)(OffCart);