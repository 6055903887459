import React, { Component } from 'react';
import { connect, ReactReduxContext } from 'react-redux';
import { NewsletterSignup  } from 'cb-design-system';
import { ducks } from '../../ducks';

const newsletterOperations = ducks.newsletter.operations

class Newsletter extends Component {

  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleNewsletterPost = this.handleNewsletterPost.bind(this);
  }

  componentDidMount() {
    if (!this.props.promotionId) {
      this.props.promotionId = this.props.fields.promotionId;
    }
  }

  componentWillReceiveProps(newProps){
    if(newProps.isLoading !== this.props.isLoading){
      this.setState({
        isLoading: newProps.isLoading
      });
    }

    if(newProps.results !== this.props.results){
      this.setState({
        results: newProps.results
      });
    }
  }

  handleNewsletterPost(values){
    this.props.postNewsletter(values);
  }

  handleInputChange(e) {
    if (this.props[e.target.name] !== e.target.value) {
      this.props.changeInput(e);
    }
  }

  render() {

    const model = {
      title: this.props.fields?.title || '',
      subtitle: this.props.fields?.subtitle || '',
      text: this.props.fields?.text || '',
      promotionId: this.props.fields?.promotionId || '',
      thankYouMessage: this.props.fields?.thankYouMessage || ''
    };

    return(
      <ReactReduxContext.Consumer>
        {((context) => (
          <div className="cb-bg-color-beige-400 cb-l-padding-large--vertical cb-l-shadow-top-large">
            <NewsletterSignup
              store={context.store}
              model={model}
              changeInputHandler={this.handleInputChange}
              submitHandler={this.handleNewsletterPost}
              isLoading={this.props.isLoading}
              results={this.props.results}
            />
          </div>
          )).bind(this)
        }
      </ReactReduxContext.Consumer>
    );
  }
};

const mapStateToProps = (state) => {

  return {
    isLoading: state.newsletter.isLoading,
    isError: state.newsletter.isError,
    results: state.newsletter.results,
    err: state.newsletter.err,
    promotionId: state.newsletter.promotionId
  };
}

const mapDispatchToProps = (dispatch) => ({
  handleError: err => dispatch(newsletterOperations.receiveError(err)),
  changeInput: (e) => dispatch(newsletterOperations.changeInput(e)),
  postNewsletter: (values, apiVersion) => dispatch(newsletterOperations.postNewsletter(values,apiVersion)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Newsletter);
