import React from 'react';

const RetailProductReviews = (props) => {
  //Need to move reviews to here
  return (
    <React.Fragment>
      <div>
      {console.log("ProductReviews")}
      </div>
    </React.Fragment>
  );
};

export default RetailProductReviews;


