import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { MegaBanner, Carousel } from 'cb-design-system';

/**
 * Demonstrates usage of a Content List field type within JSS.
 * Content Lists are references to 0..n other content items.
 * In Sitecore terms, this maps by default to a Treelist field.
 */
const BannerCarousel = (props) => {

  if (!props.fields) return (<div></div>)

  const {
    bannerItems = [],
    bannerType = {}, // hero, side
    TextAlignment, // left, center, right
    Background, // default, solid
    TopPadding,
    BottomPadding
  } = props.fields;


  const {
    dataSource = ""
  } = props.rendering;

  const layout = {
    background: Background.value,
    topPadding: TopPadding?.fields.key.value || 'medium',
    bottomPadding: BottomPadding?.fields.key.value || 'medium',
  }

  const isEditing = props.sitecoreContext && props.sitecoreContext.pageEditing;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 780,
        settings: {
          arrows: false
        }
      },
      {
        breakpoint: 580,
        settings: {
          arrows: false
        }
      }
    ]
  }

  return (

    <React.Fragment>
       <Carousel settings={settings} type={`banner-${bannerType.value}`}>
        {
            bannerItems && bannerItems.map((listItem, index) => {

            const model = {
              image: listItem.fields.image,
              videoUrl: listItem.fields.videoUrl,
              title: listItem.fields.title,
              titleTag: listItem.fields.titleTag,
              subtitle: listItem.fields.subtitle,
              text: listItem.fields.text,
              link1: listItem.fields.link1,
              link2: listItem.fields.link2,
              bannerStickerImage: listItem.fields.bannerStickerImage,
              autoplay: listItem.fields.autoplay,
              displayControls: listItem.fields.displayControls,
              playsound: listItem.fields.playsound,
              personalizationScenario: listItem.fields.personalizationScenario,
              id: listItem.id
            }

            return (
              //<div key={`localListItem-${index}`}>//prevent blk border around a picture in Chromev83
                <MegaBanner
                  {...props}
                  key={`carousel-banner-${index}`}
                  type={bannerType.value}
                  model={model}
                  align={TextAlignment.value}
                  layout={layout}
                  htmlId={`carousel-banner-${index}-${dataSource}`}
                  isEditing={true} // Carousel uses its own intenal Lazy Loading. This forces the component to use teh regular image
                />
              //</div>
              )
            })
        }
      </Carousel>
    </React.Fragment>
  );
};

export default withSitecoreContext()(BannerCarousel);


