import React, { useState, useEffect } from 'react';
import {
  mapProduct,
  TextWCardList as TextWList,
  CategoryCard,
  Container,
  Row,
  Col,
  Wrapper,
  Loading,
  impressionsGA,
  gaTracker } from 'cb-design-system';
import { connect } from 'react-redux';
import { ducks } from "../../ducks";

const { cart: cartDuck, menu: menuDuck, wishlist: wishlistDuck, location: locationDuck } = ducks;
const { operations } = cartDuck
const { operations: menuOperations } = menuDuck
const { operations: locationOperations } = locationDuck;
const { postItem: duckPostCart } = operations
const { setDefault } = menuOperations

const handleWishlist = wishlistDuck.operations.handleWishlist

const EatMenuCategories = (props) => {

  const { results = [], currentCategory, addToOrder, noLocation, saveDefault, location, userModified, toggleChangeNoLocationModal } = props

  const layout = {
    background: "default",
    topPadding: "medium",
    bottomPadding: "medium"
  }

  const exampleCardDetail = {
    "Name": "1",
    "DisplayName": "Loading",
    "Products": [],
    "Categories": []
  }


  const filteredToShow = results.filter(category => currentCategory.normalized === category.CategoryContentPageName.toLowerCase())
  let rendered;

  console.log('Filtered: ', filteredToShow);

  if(filteredToShow.length === 1) {
    rendered = filteredToShow[0] || exampleCardDetail
  } else {
    rendered = results || exampleCardDetail
  }

  return(
  (!results.length) ? <Loading isLoading={true}/> : (Array.isArray(rendered)) ?
    <FullList {...props}
    layout={layout}
    addToOrder={addToOrder}
    noLocation={noLocation}
    saveDefault={saveDefault}
    toRender={rendered}
    location={location}/> : <SingleCategory {...props}
    layout={layout}
    addToOrder={addToOrder}
    noLocation={noLocation}
    saveDefault={saveDefault}
    toRender={rendered}
    location={location}/>)
};

const mapStateToProps = (state) => {
  return {
    results : state.menu.results,
    currentCategory: state.menu.currentCategory,
    noLocation: !(state.location?.location?.olo_unique_name || state.location?.location?.oloUniqueName),
    location: !(state.location?.location?.olo_unique_name || state.location?.location?.oloUniqueName),
    userModified: state?.location?.userModified
  };
}

const mapDispatchToProps = (dispatch) => ({
  addToOrder: (postData) => dispatch(duckPostCart(postData)),
  saveDefault: (defaultOption) => dispatch(setDefault(defaultOption)),
  handleFavorite: (productId, isAdd) => dispatch(handleWishlist(productId, isAdd)),
  toggleChangeNoLocationModal: (active) => dispatch(locationOperations.toggleChangeNoLocationModal(active)),
});

const FullList = (props) => {
  const [toShow, setToShow] = useState(1);
  const {toRender = [], layout} = props

  return (
    <div>
      <SingleCategory {...props} toRender={toRender}/>
    </div>

  )
}

const SingleCategory = (props) => {
  const {layout, addToOrder, noLocation, saveDefault, toRender, location, isEditing} = props
  // const childCategoriesPresent = Boolean(toRender.Categories.length)

  const setModel = (listItem) => {
    return {
      image: listItem.fields.image,
      media: listItem.fields.image,
      link: listItem.fields.link,
      title: listItem.fields.title,
    };
  }

  let cards = [];

  if(toRender.length) {
    cards = toRender.map(category => {

      const model = {
        "image": {
          "value": {
            "src": category.Image.Thumbnail || '',
            "alt": category?.Image?.Alt || category.DisplayName,
          }
        },
        "title":  {
          "value": category.DisplayName
        },
        link: {
          value: {
            'href': category.CategoryContentPagePath.replace('/sitecore/content/crackerbarrel/brandsite/home', ''),
          }
        },
      }
      return {fields: model}
    })
  }

  return (

    <React.Fragment>
    
      {/* <TextWList
        title=""
        cards={cards}
        layout={layout}
        addToOrder={addToOrder}
        noLocation={noLocation}
        saveDefault={saveDefault}
        {...props}
      /> */}
      <Container className={`
          d-block
          cb-card--small
          mt-5
        `}>
        
        
        <Row>
        {
            cards.map((card, index) => {
              const model = setModel(card);
      
              return (
                <Col lg={4} md="6" className={`cb-card__category-container ${(isEditing) ? "cb-card--scEdit" : ""}`} key={`cards-col-${index}`} >
                  <CategoryCard model={model} {...props}/>
                </Col>
              )
            })
          }
        </Row>
      </Container>

    </React.Fragment>
  )
}


export default connect(mapStateToProps, mapDispatchToProps)(EatMenuCategories);
