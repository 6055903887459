import React, { useEffect, useState } from 'react';
import { TextWCardList as TextWList, FeaturedCardList } from 'cb-design-system';
import { connect } from 'react-redux';
import { ducks } from "../../ducks";

const { menu: menuDuck, cart: cartDuck } = ducks;

const { operations } = menuDuck
const { operations: cartOperations } = cartDuck
const { getFeatures } = operations
const { postItem: duckPostCart } = cartOperations

const EatFeaturedCards = (props) => {
  return <FeaturedCardList {...props}/>
}

const mapStateToProps = (state) => {
  return {
    features : state.menu.features,
    currentCategory: state.menu.currentCategory,
    location: state.location.location,
    noLocation: !(state.location?.location?.olo_unique_name || state.location?.location?.oloUniqueName)
  };
}

const mapDispatchToProps = (dispatch) => ({
  getFeatured: (newPage) => dispatch(getFeatures(newPage)),
  addToOrder: (postData) => dispatch(duckPostCart(postData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EatFeaturedCards);
