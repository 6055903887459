import React from 'react';
import { LocationMap as DesignSystemLocationMap, Wrapper } from 'cb-design-system';
import { connect, ReactReduxContext } from 'react-redux';

const LocationMap = (props) => (


  <ReactReduxContext.Consumer>
    {((context) => {
      return (
        <Wrapper>
          <DesignSystemLocationMap
            store={context.store}
            {...props} />
        </Wrapper>
      )
    }
    ).bind(this)
    }
  </ReactReduxContext.Consumer>
)

export default connect()(LocationMap);
