import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { CardList } from 'cb-design-system';

/**
 * Demonstrates usage of a Content List field type within JSS.
 * Content Lists are references to 0..n other content items.
 * In Sitecore terms, this maps by default to a Treelist field.
 */
const Cards = (props) => {

  const {
    totalCardsInRow = {},
    cardDisplayType = {},
    cardItems = [],
    Background, // default, solid
    TopPadding,
    BottomPadding
  } = props.fields;

  const layout = {
    background: Background.value,
    topPadding: TopPadding?.fields.key.value || 'medium',
    bottomPadding: BottomPadding?.fields.key.value || 'medium',
  }

  const isEditing = props.sitecoreContext && props.sitecoreContext.pageEditing;

  return (
    <CardList
      cards={cardItems}
      totalCardsInRow={totalCardsInRow.value}
      cardDisplayType={cardDisplayType.value}
      layout={layout}
      isEditing={isEditing}
    />
  );
};

export default withSitecoreContext()(Cards);


