import React from 'react';
import { Text, Image, RichText, Link } from '@sitecore-jss/sitecore-jss-react';

const PromoContent = (props) => (
  <div className="section background-plain-light">
    <div className="container large">
      <div className="row ">
        <div className="col-md-12 padding-none">
          <div className="content-block video-spotlight ">
            <div className="image-preview">
              <Image field={props.fields.image} />
            </div>
            <div className="body-container">
              <div className="copy no-video">
                <div className="title">
                  <span className="flower-with-leaf"><Text field={props.fields.title} /></span>
                </div>

                <div className="subtitle">
                  <Text field={props.fields.subtitle} />
                </div>

                <div className="body">
                  <RichText field={props.fields.text} />
                </div>

                <div className="button cta button-simple">
                  <Link field={props.fields.link} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PromoContent;
