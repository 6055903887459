import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { MemberIdModal as BsMemberIdModal } from "cb-design-system";
import React from "react";

const MemberId = (props) => {
  const model = props.fields;

  return (
    <React.Fragment>
      <BsMemberIdModal model={model} />
    </React.Fragment>
  );
};

export default withSitecoreContext()(MemberId);
