import React, { useEffect, useState } from 'react';
import { EatNav as DesignEatNav, MediaQuery, LocationSelection as DsLocationSelection } from 'cb-design-system';
import { connect, ReactReduxContext } from 'react-redux';
import { Preview } from "../EatMenuDetail"

import { ducks } from "../../ducks";

const { menu: menuDuck } = ducks;

const { operations } = menuDuck;
const { changeCategory: duckChangeCategory } = operations;

const EatNavigation = (props) => {

  const [locationCheck, setLocationCheck] = useState("");
  const {getMenu, location} = props;

  useEffect(() => {

    const olo_unique_name = location?.olo_unique_name || location?.oloUniqueName;

    if(olo_unique_name) {

      if(olo_unique_name !== locationCheck) {
        setLocationCheck(olo_unique_name);
        getMenu();
      }
    }
  }, [location])

  // TODO: Make an Web API Call to get menu products data by passing storeId is optional.
  // Web API is /sitecore/api/GetEatProductsByStoreAndCategory/{storeId}   - You can use "lebanon-tn" to pass {storeId}
  // Example Web API - /sitecore/api/GetEatProductsByStoreAndCategory/lebanon-tn

  return(
    <>
      <MediaQuery minWidth={991}>
        <Preview/>
        <DesignEatNav {...props}/>
      </MediaQuery>
      <MediaQuery maxWidth={990}>
        <Preview/>
        <DesignEatNav {...props}/>
      </MediaQuery>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    navLinks : state.menu.categories || [],
    location : state.location.location,
    currentCategory: state.menu.currentCategory
  };
}

const mapDispatchToProps = (dispatch) => ({
  getMenu: () => dispatch(operations.getMenu()),
  changeCategory: (newCat) => dispatch(duckChangeCategory(newCat))
});

export default connect(mapStateToProps, mapDispatchToProps)(EatNavigation);
