import React, { useState, useEffect } from 'react';
import { connect, ReactReduxContext } from 'react-redux';
import { FetchError } from 'cb-design-system';

import {
  ducks
} from '../../ducks';

const errorOperations =  ducks.error.operations

const ErroHandler = (props) => {
  const  {
    err = {},
    resetError
  } = props

  useEffect(() => {
  }, [props.err]);

  return (
    <ReactReduxContext.Consumer>
      {((context) => (
        <FetchError
          error={err}
          errorHandler={resetError}
        />
      )).bind(this)
    }
    </ReactReduxContext.Consumer>
  );
}

const mapStateToProps = (state) => {
  return {
    err: state.error.err
  };
}

const mapDispatchToProps = (dispatch) => ({
  resetError: () => dispatch(errorOperations.resetError())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErroHandler);
