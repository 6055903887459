import React from 'react';
import { RetailCategoryCards as  DesignRetailCategoryCards } from 'cb-design-system';

const RetailCategoryCards = (props) => {
  return (
    <DesignRetailCategoryCards {...props}/>
  );
};


export default RetailCategoryCards;




