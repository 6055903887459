import React from 'react';

import { Footer as DesignFooter } from 'cb-design-system';
import LoadScript from 'react-load-script';

const Footer = (props) => {

  const { fields, footerLinks, smallFooterLinks, socialLinks } = props.fields.data;

  const model = {
    copyrightText: fields.copyrightText.jss,
    socialTitle: fields.socialTitle.jss,
    downloadAppUrl: fields.downloadAppUrl.jss,
  }

  const navigation = {
    footerLinks: footerLinks.children,
    smallFooterLinks: smallFooterLinks.children,
    socialLinks: socialLinks.children
  }

  return (
    <>
      <div>
      <DesignFooter navigation={navigation} model={model} isCnbSite={props.isCnbSite}></DesignFooter>
      </div>
      <LoadScript
        url="https://ws.audioeye.com/ae.js"
      />
    </>
  );

};

export default Footer;
