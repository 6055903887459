import React from 'react';
import { VideoImage, Wrapper, Container } from 'cb-design-system';

const Media = (props) => {

  const {
    image = {},
    videoUrl = {},
    Background = {},
    TopPadding = {},
    BottomPadding = {}
  } =  props?.fields;
  
  const mainLayout = {
    background: Background.value || 'solid',
    topPadding: TopPadding?.fields.key.value || 'medium',
    bottomPadding: BottomPadding?.fields.key.value || 'medium',
  }

  return (
    <Wrapper layout={mainLayout}>
      <Container>
        <VideoImage video={videoUrl} image={image} />
      </Container>
    </Wrapper>
  )
};

export default Media;
