import React from 'react';
import { Tab as DynamicTab, Tabs as DynamicTabs } from "react-bootstrap";
import { Placeholder, withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { Wrapper, Container } from 'cb-design-system';

class Tabs extends React.Component {

  constructor(props, context) {
    if (props.fields != 'undefined') {
      super(props, context);
      this.state = {
        key: props.fields.TabsList.value.split("&")[0].split("=")[1].replace(/%20/g, " ").replace(/%2F/g, " "),
        NameValueArray: props.fields.TabsList.value.split("&"),
        rendering: props.rendering,
        isEditing: props.sitecoreContext && props.sitecoreContext.pageEditing
      };

      this.layout = {
        background: props.fields.Background.value,
        topPadding: props.fields.TopPadding?.fields.key.value || 'medium',
        bottomPadding: props.fields.BottomPadding?.fields.key.value || 'medium',
      }
    }
  }

  render() {
    if (this.props.fields == 'undefined') {
      return (
        <Wrapper layout={this.layout}>
          <Container>
            <div>Please select datasource</div>
          </Container>
        </Wrapper>
      );
    } else {
      return (
        <Wrapper layout={this.layout}>
          <Container>
            <DynamicTabs
              id="controlled-tab-example"
              activeKey={this.state.key}
              onSelect={key => this.setState({ key })}
            >
              {this.state.NameValueArray.map((item, index) => {
                let value = item.split("=")[1]
                let headerValue = value.replace(/%20/g, " ").replace(/%2F/g, " ");

                return (
                  <DynamicTab eventKey={this.state.isEditing ? this.state.key : headerValue} title={headerValue}>
                    {this.state.isEditing && <p>{headerValue}</p>}
                    <Placeholder name={`tab-${index + 1}`} rendering={this.state.rendering} />
                  </DynamicTab>
                )
              })}
            </DynamicTabs>
          </Container>
        </Wrapper>
      );
    }
  }
};
export default withSitecoreContext()(Tabs);