import React from 'react';
import { LocationList as DsLocationList } from 'cb-design-system';


const LocationList = (props) => {
  const { fields } = props
  return (
    <DsLocationList fields={fields}/>
  );
};

export default LocationList;


