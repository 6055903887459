import React from 'react';
import { Separator as DesignSeparator } from 'cb-design-system';

const Separator = (props) => {

    const topPadding = (props.params && props.params?.topPadding?.fields?.key?.value) || 'medium';
    const bottomPadding = (props.params && props.params?.bottomPadding?.fields?.key?.value) || 'medium';
    const hide = (props.params && props.params?.hide) || "0";
    const lineDisplayStyle = (props.params && props.params?.lineDisplayStyle) || 'solid';
    const background = (props.params && props.params?.background) || 'default';

  return(
    <DesignSeparator topPadding={topPadding} bottomPadding={bottomPadding} image={hide} display={lineDisplayStyle} background={background} />
  );
};

export default Separator;
