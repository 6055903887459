import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { LandingPage, Background } from 'cb-design-system';

/**
 * Demonstrates usage of a Content List field type within JSS.
 * Content Lists are references to 0..n other content items.
 * In Sitecore terms, this maps by default to a Treelist field.
 */
const LP = (props) => {
  let urlparams = window.location.search;
  let depam = urlparams.replace(/\?/g, "");
  var atob = require('atob');
  let decryptedparams = new URLSearchParams(depam);
  decryptedparams = [...decryptedparams][0][0];
  decryptedparams = atob(decryptedparams).toLowerCase();
  decryptedparams = new URLSearchParams(decryptedparams)

  let tel = decryptedparams.get("storephone")
  let orderID = decryptedparams.get("orderid")
  let catalog = decryptedparams.get("catalog")
  let storenumber = decryptedparams.get("storenumber")
  let storephone = decryptedparams.get("storephone")
  let firstname = decryptedparams.get("firstname")
  let lastname = decryptedparams.get("lastname")
  let phonenumber = decryptedparams.get("phonenumber")
  let carmake = decryptedparams.get("make")
  let carmodel = decryptedparams.get("model")
  let carcolor = decryptedparams.get("color")

  const isEditing = props.sitecoreContext && props.sitecoreContext.pageEditing;
  const model = {
    title: {
      value: '<strong>You\'re Here!</strong>'
    },
    modaltitle: {
      value: '<strong>Got It!</strong>'
    },
    text: {
      value: 'Thanks for letting us know you’re here! We\'ll bring out your meal shortly.'
    },
    subtitle: {
      value: 'HOLD TIGHT - WE\'LL BE RIGHT OUT'
    },
    link1: {
      value: {
        'text': 'Check Out What\'s New',
        'title': 'Check Out What\'s New',
        'href': '/',
        'class': 'cb-button-primary'
      }
    },
    link2: {
      value: {
        'text': 'Need Help? Contact The Store',
        'title': 'Need Help? Contact The Store',
        'href': 'tel:' + storephone
      }
    },
    orderid: orderID || '',
    catalog: catalog || '',
    storenumber: storenumber || '',
    storephone: storephone || '',
    firstname: firstname || '',
    lastname: lastname || '',
    phonenumber: phonenumber || '',
    make: carmake || '',
    model: carmodel || '',
    color: carcolor || ''
  };
  const errorModel = {
    title: {
      value: '<strong>Oh, Dear...</strong>'
    },
    subtitle: {
      value: 'Something\'s Not Right!'
    },
    text: {
      value: 'We\'re Having an unexpected issue. Please try to check in again or call the store for assistance.'
    },
    link1: {
      value: {
        'text': 'Call The Store',
        'title': 'Call The Store',
        'href': 'tel:' + storephone
      }
    },
  };
  return (
    <div>
      <LandingPage htmlId={`lp`} model={model} errorModel={errorModel} />
    </div>
  )

};
export default withSitecoreContext()(LP);
