import React, { useState, useEffect } from 'react';
import { SitecoreContext, SitecoreContextFactory } from '@sitecore-jss/sitecore-jss-react';
import { Route, Switch } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import { CookiesProvider, withCookies } from 'react-cookie';
import componentFactory from './temp/componentFactory';
import RouteHandler from './RouteHandler';

import { Provider } from 'react-redux';
import { store, persistor } from './configureStore';

import { PersistGate } from 'redux-persist/integration/react';
import DummyPersist from './components/Utils/DummyPersist';
import ErrorHandler from './components/ErrorHandler';

import { GTMProvider } from 'cb-design-system';

// This is the main JSX entry point of the app invoked by the renderer (server or client rendering).
// By default the app's normal rendering is delegated to <RouteHandler> that handles the loading of JSS route data.

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /path
export const routePatterns = [
  '/:lang([a-z]{2}-[A-Z]{2})/:sitecoreRoute*',
  '/:lang([a-z]{2})/:sitecoreRoute*',
  '/:sitecoreRoute*',
];

// ApolloProvider: provides an instance of Apollo GraphQL client to the app to make Connected GraphQL queries.
//    Not needed if not using connected GraphQL.
// SitecoreContext: provides component resolution and context services via withSitecoreContext
// Router: provides a basic routing setup that will resolve Sitecore item routes and allow for language URL prefixes.
const AppRoot = ({ path, Router, graphQLClient, ssrState }) => {
  const [ssrRenderComplete, setSsrRenderComplete] = useState(false);
  const [contextFactory] = useState(new SitecoreContextFactory());

  useEffect(() => {
    if (ssrState && ssrState.sitecore && ssrState.sitecore.route) {
      // set the initial sitecore context data if we got SSR initial state
      contextFactory.setSitecoreContext({
        route: ssrState.sitecore.route,
        itemId: ssrState.sitecore.route.itemId,
        ...ssrState.sitecore.context,
      });
    }
  }, [ssrState, contextFactory]);

  const routeRenderFunction = (props) => {
    return (
      <RouteHandler
        route={props}
        ssrState={ssrRenderComplete ? null : ssrState}
        contextFactory={contextFactory}
        setSsrRenderComplete={setSsrRenderComplete}
        ssrRenderComplete={ssrRenderComplete}
      />
    );
  }

  /*
    * Sitecore SSR does not play well with the state persist during edit mode.
    * During SSR we remove the persist by passing a dummy component'
    */
  let PersistClient = PersistGate;
  const runtime = process.env.RUNTIME;
  if (runtime !== 'browser') {
    PersistClient = DummyPersist;
  }

  const gtmParams = {
    id: "GTM-K5DSR9V",
  };

  return (
    <CookiesProvider>
      <Provider store={store}>
        <PersistClient loading={null} persistor={persistor}>
          <ErrorHandler />
          <ApolloProvider client={graphQLClient}>
            <SitecoreContext
              componentFactory={componentFactory}
              context={contextFactory}
            >
              <Router location={path} context={{}}>
                <GTMProvider state={gtmParams}>
                  <Switch>
                    {routePatterns.map((routePattern) => (
                      <Route
                        onUpdate={() => {
                          console.log("updated");
                          window.scrollTo(0, 0);
                        }}
                        key={routePattern}
                        path={routePattern}
                        render={routeRenderFunction}
                      />
                    ))}
                  </Switch>
                </GTMProvider>
              </Router>
            </SitecoreContext>
          </ApolloProvider>
        </PersistClient>
      </Provider>
    </CookiesProvider>
  );
}

export default withCookies(AppRoot);
