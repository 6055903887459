import React, { Fragment } from "react";
import { MobilePayLanding as MobPayLanding } from "cb-design-system";
import { ReactReduxContext } from "react-redux";

const MobilePay = (props) => {
  return (
    <Fragment>
      <ReactReduxContext.Consumer>
        {((context) => {
          return <MobPayLanding {...props} store={context.store} />;
        }).bind(this)}
      </ReactReduxContext.Consumer>
    </Fragment>
  );
};

export default MobilePay;
