
import React from 'react';
import { Checkout as DesignCheckout } from 'cb-design-system';
import { connect, ReactReduxContext } from 'react-redux';
import { ducks } from "../../ducks";

import { AzureADClient, authProviderClient, AuthenticationState } from '../../AuthComponent';

const { cart: cartDuck } = ducks;
const { operations } = cartDuck
const { setDeliveryInfo: duckDeliveryHandler,
  submitCart,
  closeCheckout,
  postDeliveryMode,
  resetGoToCheckout,
  postTip,
  postPromoCode,
  setCurbside,
  setDeliveryInfo,
  deletePromoCode,
  validateOrder,
  hideValidateCart,
  getGiftCardSchema,
  checkGiftCard,
  removeGiftCard,
  removeAllGiftCards,
  setRetail,
  getSavedCreditCards,
  saveDeliveryMode,
  setMixCartCustomFields,
  postNewsletter
 } = operations

const layout = {
  background: "default",
  padding: "medium",
}

const Checkout = (props) => {
  return (
    <ReactReduxContext.Consumer>
      {((context) => {
        return (
          <AzureADClient provider={authProviderClient} reduxStore={context.store}>
            {
              ({login, logout, authenticationState, error, accountInfo}) => {
                return(
                <DesignCheckout
                  store={context.store}
                  authenticationState={authenticationState}
                  accountInfo={accountInfo}
                  postNewsletter={postNewsletter}
                  {...props}
                />)
              }
            }
          </AzureADClient>
        )}
      ).bind(this)
    }
    </ReactReduxContext.Consumer>
  )
}

const mapStateToProps = (state) => {
  return {
    deliveryMethod : state.cart.deliveryMode,
    cart : state.cart.cart,
    cost: state.cart.price,
    checkoutSuccess: state.cart.checkoutSuccess,
    submitInfo: state.cart.submitInfo,
    location: state.location.location,
    validateSuccess: state.cart.validateSuccess,
    deliveryFail: state.cart.deliveryFail,
    food: state.cart.food,
    cater: state.cart.cater,
    fullCart: state.cart,
    showGiftCard: Boolean(state.cart.giftCardScheme || state.cart.isRetail),
    giftCardInfo: state.cart.giftCardInfo,
    schema: state.cart.giftCardScheme,
    applePayScheme: state.cart.applePayScheme,
    googlePayScheme: state.cart.googlePayScheme,
    shippingModes: state.cart.shippingModes || [],
    destructuredCart: state.cart.destructuredCart,
    savedPromos: state.cart.savedPromos || [],
    savedCards: state.cart.savedCards || [],
    isCatering: state.cart.isCatering,
    isFood: state.cart.isFood,
    isRetail: state.cart.isRetail,
    isSendingPromoCode: state.cart.isSendingPromoCode,
    isSubmittingCart: state.cart.isSubmittingCart,
  };
}

const mapDispatchToProps = (dispatch) => ({
  deliveryHandler: (...args) => dispatch(duckDeliveryHandler(...args)),
  validateHandler: (payload) => dispatch(validateOrder(payload)),
  hideValidateCart: () => dispatch(hideValidateCart()),
  submitHandler: (payload) => dispatch(submitCart(payload)),
  changeCheckoutSuccess: () => dispatch(closeCheckout()),
  setDeliveryMode: (deliveryModes) => dispatch(postDeliveryMode(deliveryModes)),
  resetGoToCheckout: () => dispatch(resetGoToCheckout()),
  setTip: (amt, percent) => dispatch(postTip(amt, percent)),
  postPromoCode: promo => dispatch(postPromoCode(promo)),
  deletePromoCode: promo => dispatch(deletePromoCode(promo)),
  setDeliveryInfo: (deliveryState,street1, street2, city, zip, phone, special, deliveryMode) =>
    dispatch(setDeliveryInfo(deliveryState,street1, street2, city, zip, phone, special, deliveryMode)).catch(err => {throw err}),
  setRetail: (addressInfo, deliveryMode) =>
    dispatch(setRetail(addressInfo, deliveryMode)),
  setCurbside: (...args) => dispatch(setCurbside(...args)),
  setMixCartCustomFields: () => dispatch(setMixCartCustomFields()),
  // eslint-disable-next-line no-restricted-globals
  handleBackToCart: (e) => {

    const elem = e.target;
    const processing = elem.getAttribute('processing');

    if(!processing) {

      e.preventDefault();
      elem.setAttribute('processing', true);

      return dispatch(postTip(0, 0)).then(() => {
        elem.click();
      })
    }

    return true;
  },
  checkGiftCard: (num, pin) => dispatch(checkGiftCard(num, pin)),
  getGiftCardSchema: () => dispatch(getGiftCardSchema()),
  removeGiftCard: () => dispatch(removeGiftCard()),
  removeAllGiftCards: () => dispatch(removeAllGiftCards()),
  getSavedCreditCards: () => dispatch(getSavedCreditCards()),
  saveDeliveryMode: (travelType) => dispatch(saveDeliveryMode(travelType)),
  postNewsletter: (values, apiVersion) => dispatch(postNewsletter(values,apiVersion))
});

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
