import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import React from "react";
import { ReactReduxContext } from "react-redux";
import { AzureADClient, authProviderClient } from "../../AuthComponent";
import { PausedAccountModal as BsPausedAccountModal } from "cb-design-system";

const PausedAccountModal = props => {
  const model = props.fields;

  return (
    <React.Fragment>
      <ReactReduxContext.Consumer>
        {(context => {
          return (
            <AzureADClient
              forceLogin={false}
              provider={authProviderClient}
              reduxStore={context.store}
            >
              {({ authenticationState, error }) => {
                if (error != null) {
                  console.error(error);
                }

                return (
                  <BsPausedAccountModal
                    model={model}
                    authenticationState={authenticationState}
                    store={context.store}
                  />
                );
              }}
            </AzureADClient>
          );
        }).bind(this)}
      </ReactReduxContext.Consumer>
    </React.Fragment>
  );
};

export default withSitecoreContext()(PausedAccountModal);
