import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect, ReactReduxContext } from 'react-redux';
import { LocationBanner } from 'cb-design-system';
import {
  ducks
} from '../../ducks';

const locationOperations =  ducks.location.operations
const headerOperations =  ducks.header.operations
const waitlistOperations =  ducks.waitlist.operations

class LocationBar extends Component {

  constructor(props) {
    super(props);

    this.handleLocationSuccess = this.handleLocationSuccess.bind(this);
    this.handleLocationError = this.handleLocationError.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleWaitlistPost = this.handleWaitlistPost.bind(this);
  }

  componentDidMount() {
    if (!this.props.latLon) {
      // this.props.changeLatLon(this.props.latLon);
    }

    // this.props.getLocationByGps();
  }

  componentWillReceiveProps(newProps){
    if(newProps.latLon !== this.props.latLon){
      // this.props.changeLatLon(newProps.latLon);
      // this.props.getLocationByGps();
    }

    if(newProps.isLoading !== this.props.isLoading){
      this.setState({
        isLoading: this.props.isLoading
      });
    }
  }

  handleWaitlistPost(){
    this.props.postWaitlist();
  }

  handleInputChange(e) {
    if (this.props[e.target.name] !== e.target.value) {
      this.props.changeInput(e);
    }
  }

  handleLocationSuccess(position) {
    if(typeof position.coords === 'object') {
      // this.props.changeLatLon(position.coords);
      // this.props.getLocationByGps();
    }
  }

  handleLocationError(err) {
    this.props.handleError(err)
  }

  render() {
    return(
      <ReactReduxContext.Consumer>
        {((context) => (
        <LocationBanner
          store={context.store}
          results={this.props.results}
          location={this.props.location}
          isFetching={this.props.isFetching}
          isError={this.props.isError}
          err={this.props.err}
          onSuccess={this.handleLocationSuccess}
          onError={this.handleLocationError}
          toggleWaitlist={this.props.toggleWaitlist}
          waitlistInputHandler={this.handleInputChange}
          // waitlistSubmitHandler={this.handleWaitlistPost}
          waitlistLoading={this.props.isLoading}
          changeZipCode={this.props.changeZipCode}
          getLocationByGps={this.props.getLocationByGps}
          getLocationByZipcode={this.props.getLocationByZipcode}
          setLocation={this.props.setLocation}
          disclaimer={this.props.fields?.Disclaimer}
        />
        )).bind(this)
      }
      </ReactReduxContext.Consumer>
    );
  }
};
//import { from } from 'apollo-link';

LocationBar.propTypes = {
  latLon: PropTypes.object.isRequired,
  getLocationByIp: PropTypes.func.isRequired,
  getLocationByGps: PropTypes.func.isRequired,
  getLocationByZipcode: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    latLon: state.location.latLon,
    isFetching: state.location.isFetchingLocation,
    isLoading: state.waitlist.isLoading,
    isError: state.location.isError,
    location: state.location.location,
    results: state.location.locationResults,
    err: state.location.err
  };
}

const mapDispatchToProps = (dispatch) => ({
  changeLatLon: text => dispatch(locationOperations.changeLatLon(text)),
  getLocationByIp: () => dispatch(locationOperations.getLocationByIp()),
  getLocationByGps: () => dispatch(locationOperations.getLocationByGps()),
  setLocation: location => dispatch(locationOperations.setLocation(location)),
  changeZipCode: text => dispatch(locationOperations.changeZipCode(text)),
  getLocationByZipcode: () => dispatch(locationOperations.getLocationByZipcode()),
  handleError: err => dispatch(locationOperations.receiveError(err)),
  toggleWaitlist: () => dispatch(waitlistOperations.toggleWaitlist()),
  changeInput: text => dispatch(waitlistOperations.changeInput(text)),
  postWaitlist: () => dispatch(waitlistOperations.postWaitlist())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationBar);
