import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Button, CardList, Heading, ContentBlock } from "cb-design-system";

class HolidayWizard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      StepOneSelection: "select",
      StepTwoSelection: "select",
      StepThreeSelection: "select",

      firstSelectionText: "",
      secondSelectionText: "",

      hideOne: false,
      hideTwo: true,
      hideThree: true,
      finalChoices: true,
      wizardBox: false,
      width: 0,
      height: 0,

      highlightImage: "A43E3FFA0D134C8B97C68C056D705746.ashx",
    };

    // step one
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeTwo = this.handleChangeTwo.bind(this);
    this.handleChangeThree = this.handleChangeThree.bind(this);
    this.resetButtons = this.resetButtons.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  resetButtons() {
    this.setState({
      StepOneSelection: "select",
      StepTwoSelection: "select",
      StepThreeSelection: "select",
      firstSelectionText: "",
      secondSelectionText: "",
      hideOne: false,
      hideTwo: true,
      hideThree: true,
      finalChoices: true,
      wizardBox: false,
    });
  }


  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  truncate(str) {
    if (this.state.width > 700) {
      return str.length > 10 ? str.substring(0, 65) + "..." : str;
    } else if (this.state.width < 700 && this.state.width >= 401) {
      return str.length > 10 ? str.substring(0, 50) + "..." : str;
    } else if (this.state.width < 400) {
      return str.length > 10 ? str.substring(0, 30) + "..." : str;
    }
  }

  handleChange(event) {
    this.setState({ StepOneSelection: event.target.value });

    switch (event.target.value) {
      case "select":
        // code block
        this.setState({ hideTwo: true });
        this.setState({ hideThree: true });
        this.setState({ finalChoices: true });
        this.setState({ firstSelectionText: "" });

        break;
      case "individual":
        // code block
        this.setState({ hideOne: true });
        this.setState({ hideTwo: true });
        this.setState({ hideThree: false });
        this.setState({ firstSelectionText: "Party Size: Individual" });
        this.setState({ highlightImage: "94BFA9D96A294E828D54146A5E4ED798.ashx" });
        break;
      case "lessthansix":
        // code block
        this.setState({ hideOne: true });
        this.setState({ hideTwo: false });
        this.setState({ hideThree: true });
        this.setState({ firstSelectionText: "Party Size: Fewer Than Six" });
        this.setState({ highlightImage: "DFC1834C0B7D44B083F44409023257E5.ashx" });
        break;
      case "morethansix":
        // code block
        this.setState({ hideOne: true });
        this.setState({ hideTwo: false });
        this.setState({ hideThree: true });
        this.setState({ firstSelectionText: "Party Size: More Than Six" });
        this.setState({ highlightImage: "C393A5E05B294625A8F5EA04EC79A2A4.ashx" });
        break;
      default:
        // code block
        this.setState({ hideOne: false });
        this.setState({ hideTwo: true });
        this.setState({ hideThree: true });
        this.setState({ firstSelectionText: "" });
    }
  }

  handleChangeTwo(event) {
    this.setState({ StepTwoSelection: event.target.value });

    switch (event.target.value) {
      case "select":
        // code block

        this.setState({ hideOne: true });
        this.setState({ hideTwo: false });
        this.setState({ hideThree: true });
        this.setState({ secondSelectionText: "" });
        break;
      case "hotnow":
        // code block

        this.setState({ hideOne: true });
        this.setState({ hideTwo: true });
        this.setState({ hideThree: false });
        this.setState({ secondSelectionText: "Timing: Hot Now" });
        this.setState({ highlightImage: "DFC1834C0B7D44B083F44409023257E5.ashx" });
        break;
      case "coldlater":
        // code block

        this.setState({ hideOne: true });
        this.setState({ hideTwo: true });
        this.setState({ hideThree: false });
        this.setState({ secondSelectionText: "Timing: Cold Later" });
        this.setState({ highlightImage: "B6EADA9A22564D19A1A21EDF13E8146D.ashx" });
        break;

      default:
      // code block
    }
  }

  handleChangeThree(event) {
    this.setState({ StepThreeSelection: event.target.value });

    switch (event.target.value) {
      case "select":
        // code block

        this.setState({ hideOne: true });
        this.setState({ hideTwo: true });
        this.setState({ hideThree: false });
        this.setState({ finalChoices: true });
        this.setState({ wizardBox: true });
        break;
      case "breakfast":
        // code block

        this.setState({ hideOne: true });
        this.setState({ hideTwo: true });
        this.setState({ hideThree: true });
        this.setState({ finalChoices: false });
        this.setState({ wizardBox: true });
        break;
      case "lunchdinner":
        // code block

        this.setState({ hideOne: true });
        this.setState({ hideTwo: true });
        this.setState({ hideThree: true });
        this.setState({ finalChoices: false });
        this.setState({ wizardBox: true });
        break;

      default:
      // code block
    }
  }

  render() {
    //constants to hide other elements

    const hideOne = this.state.hideOne ? "visuallyhidden" : "";
    const hideTwo = this.state.hideTwo ? "visuallyhidden" : "";
    const hideThree = this.state.hideThree ? "visuallyhidden" : "";
    const finalChoices = this.state.finalChoices ? "visuallyhidden" : "";
    const wizardBox = this.state.wizardBox ? "visuallyhidden" : "";
    const highlightImage = this.state.highlightImage;

    const one = [0];
    const two = [0, 1];

    // const store = JSON.parse(sessionStorage.getItem("cb-ds"));

    const morethansixhotnowbreakfast = [
      {
        Heading: "Cinnamon Streusel Pancake Casserole",
        description:
          "Breakfast hot and ready to share on holiday mornings. Buttermilk Pancakes with Cinnamon and Streusel Topping then baked and drizzled with icing.",
        img: "/-/jssmedia/4D764B08F4FC4A07A10C397F326A79EB.ashx",
        color: "color1",
        url: "/catering/breakfast-all-day/casseroles",
      },

      {
        Heading: "Cracker Barrel Classic",
        description:
          "Customize with a selection of egg casseroles, breakfast meats and sides. Includes grits, sawmill gravy and biscuits.",
        img: "/-/jssmedia/EA3EA74FD18646D8B978C1F0FA000AAD.ashx",
        color: "color2",
        url: "/catering/breakfast-all-day/bundles",
      },

      {
        Heading: "Meat Biscuits",
        description:
          "A dozen Buttermilk Biscuits with choice of breakfast meat. Add a variety of our breakfast sides to create your own meal.",
        img: "/-/jssmedia/C6671D22D6294FA88FD2E21513525536.ashx",
        color: "color3",
        url: "/catering/breakfast-all-day/breakfast-sandwiches",
      },

      {
        Heading: "Hashbrown Casserole",
        description:
          "Pair with a variety of sides for a family breakfast ready to eat.",
        img: "/-/jssmedia/7B28015D36024C588854811D8470F985.ashx",
        color: "color3",
        url: "/catering/breakfast-all-day/casseroles",
      },
    ];

    const morethansixhotnowlunchdinner = [
      {
        Heading: "New Country Fried Turkey Catering",
        description:
          "Hot and ready to serve for holiday celebrations. Country Fried Turkey & Gravy, Three Cheese Squash Casserole, two sides and biscuits.",
        img: "/-/jssmedia/EAEFC9D6EDCE4D1B9E496907D7E1C7B9.ashx",
        color: "color2",
        url: "/catering/lunch-n-dinner/bundles",
      },

      {
        Heading: "Sunday HomeStyle Chicken",
        description:
          "Boneless chicken breasts in our buttermilk batter deep fried golden crispy. Sides and bread complete the meal.",
        img: "/-/jssmedia/39A14B1B39F842888A08E2ABD3B11D32.ashx",
        color: "color2",
        url: "/catering/lunch-n-dinner/bundles",
      },

      {
        Heading: "Grilled Chicken Tenderloins",
        description:
          "Marinated or fried tenders and choice of dipping sauce. Make the meal your own with a variety of side choices. ",
        img: "/-/jssmedia/AA239D312F5B4E71BECFAB72BB2036FB.ashx",
        color: "color3",
        url: "/catering/lunch-n-dinner/bundles",
      },

      {
        Heading: "Fresh Baked Holiday Pies",
        description:
          "There’s always room at the table for something sweet. Enjoy festive flavors like Pecan, Chocolate Pecan, or Apple Pecan Streusel.",
        img: "/-/jssmedia/0B24D25D90AD45B89AA2B2111EA0710C.ashx",
        color: "color4",
        url: "/catering/desserts",
      },
    ];

    const morethansixcoldlaterbreakfast = [
      {
        Heading: "Loaded Hashbrown Casserole",
        description:
          "Heat n’ Serve options you can pair with a variety of sides to have a pre-prepared family breakfast ready to heat with plenty of leftovers",
        img: "/-/jssmedia/5622093D8156426C955C0DCF5C78DE74.ashx",
        color: "color1",
        url: "/catering/heat-n-serve/sides",
      },

      {
        Heading: "Hashbrown Casserole",
        description:
          "A breakfast favorite you can Heat n’ Serve for an easy way to keep the fridge stocked for a family breakfast",
        img: "/-/jssmedia/C3E42DC1F5E6485DB4298A1517AB505E.ashx",
        color: "color2",
        url: "/catering/heat-n-serve/sides",
      },

      {
        Heading: "Cold, Pan-Fried Apples",
        description:
          "Pair with Heat n’ Serve options like Hashbrown Casserole to create your own breakfast for family celebrations.",
        img: "/-/jssmedia/D86E4396B95E440B8C6712CDD37D11B2.ashx",
        color: "color3",
        url: "/catering/heat-n-serve/sides",
      },

      /*
  {

  "Heading":"Curbside Pickup",
  "description":"Now available Curbside, Pick-up & Delivery.",
  "img": "/-/jssmedia/MW-Photos_Catering-Sides.jpg",
  color: "color3",
  url: "https://catering.crackerbarrel.com/"+queryString
  },
  */
    ];

    const morethansixcoldlaterlunchdinner = [
      {
        Heading: "Mac n' Cheese",
        description:
          "Heat n’ Serve sides make celebrations easy. Available in a variety of choices like Mac n’ Cheese and Hashbrown Casserole",
        img: "/-/jssmedia/BE558FD7E96941AB8C0AFEACB574BDBE.ashx",
        color: "color2",
        url: "/catering/heat-n-serve/sides",
      },

      {
        Heading: "Heat n’ Serve Three Cheese Squash Casserole",
        description:
          "Make meal prep easier by adding our seasonal Heat n’ Serve Three Cheese Squash Casserole to your next holiday meal.",
        img: "/-/jssmedia/25681DA0AB08457CA2B690AC55404179.ashx",
        color: "color3",
        url: "/catering/heat-n-serve/sides",
      },

      {
        Heading: "HOLIDAY HEAT N’ SERVE FEAST",
        description:
          "Enjoy holiday traditions at home with our chilled to reheat Holiday Feast Dinner, perfect for larger celebrations.",
        img: "/-/jssmedia/34A3AFC02BB84EE0A911749132723BC8.ashx",
        color: "color1",
        url: "/catering/heat-n-serve/holiday-offerings",
      },

      {
        Heading: "Seasonal Southern Sides",
        description:
          "Our seasonal sides complete any holiday feast. With no prep, no mess it’s easy to bring some homestyle flavor to your next holiday gathering or family dinner. ",
        img: "/-/jssmedia/23FF49F588D54BD8A4013FB507BFCFA0.ashx",
        color: "color4",
        url: "/catering/heat-n-serve/sides",
      },
    ];

    // less than six arrays

    const lessthansixhotnowbreakfast = [
      {
        Heading: "Grandma's Holiday Sampler Catering",
        description:
          "Country Fried Turkey with Gravy, Buttermilk Pancakes with Cranberry Orange Butter & Syrup, Scrambled Eggs, choice of Hashbrown Casserole or Fried Apples.",
        img: "/-/jssmedia/3D355EB5AB3F4FEB8FD52270E3C56324.ashx",
        color: "color1",
        url: "/catering/breakfast-all-day/bundles",
      },

      {
        Heading: "Breakfast Casserole",
        description:
          "Breakfast casseroles make meal planning easy. Pair with a variety of sides like fried apples and bacon to customize to your family’s tastes.",
        img: "/-/jssmedia/AC30C04386EE4321B5A19DEC380CFC55.ashx",
        color: "color2",
        url: "/catering/breakfast-all-day/casseroles",
      },

      {
        Heading: "Meat Biscuits",
        description:
          "Homemade biscuits with a variety of meat choices, pair with breakfast sides for a heartier meal.",
        img: "/-/jssmedia/6B612F655D5B455682F8E7DD7612D8A2.ashx",
        color: "color3",
        url: "/catering/breakfast-all-day/breakfast-sandwiches",
      },

      {
        Heading: "Egg Sandwiches",
        description:
          "A convenient breakfast, no utensils required! Fried egg on our rustic farmhouse bread with Colby cheese.",
        img: "/-/jssmedia/7015715D71714C6488FE127539186969.ashx",
        color: "color4",
        url: "/catering/breakfast-all-day/breakfast-sandwiches",
      },
    ];

    const lessthansixhotnowlunchdinner = [
      {
        Heading: "Grilled Chicken Tenderloins Family Meal Basket",
        description:
          "A family favorite with plenty of choices for even picky eaters. Pair grilled tenders with choice of sides and biscuits.",
        img: "/-/jssmedia/AA239D312F5B4E71BECFAB72BB2036FB.ashx",
        color: "color1",
        url: "/menu/family-meals/family-meal-baskets",
      },

      {
        Heading: "Chicken n’ Dumplins Family Meal Basket",
        description:
          "Enjoy a signature for dinner from our home to yours. Our Family Meal Basket comes with choice of two sides and bread.",
        img: "/-/jssmedia/7BB4106391934C4EAB3F7B0F0DA2A86A.ashx",
        color: "color2",
        url: "/menu/family-meals/family-meal-baskets",
      },

      {
        Heading: "Meatloaf Family Meal Basket",
        description:
          "Feed the family with our special recipe meatloaf. Our Family Meal Basket comes with choice of 2 sides and biscuits.",
        img: "/-/jssmedia/6DDF08B511124E808FC1E996792F44B6.ashx",
        color: "color2",
        url: "/menu/family-meals/family-meal-baskets",
      },

      {
        Heading: "New Country Fried Turkey Family Meal Basket",
        description:
          "A holiday homestyle comfort for family gatherings. Country Fried Turkey & Gravy. Comes with Three Cheese Squash Casserole, side choice, and biscuits.",
        img: "/-/jssmedia/DFC1834C0B7D44B083F44409023257E5.ashx",
        color: "color3",
        url: "/menu/family-meals/family-meal-baskets",
      },
    ];

    const lessthansixcoldlaterbreakfast = [
      {
        Heading: "Take n’ Bake Fried Apples",
        description:
          "Our Take n’ Bake sides are an effortless way to add some comfort  to your homemade meals. Comes cold, just heat to serve.",
        img: "/-/jssmedia/DFF6C55E20A24C4BA22323116893851E.ashx",
        color: "color1",
        url: "/catering/heat-n-serve/sides",
      },

      {
        Heading: "Take n’ Bake Hashbrown Casserole",
        description:
          "Make breakfast memories. Add our Take n’ Bake Hashbrown Casserole to your menu for a meal they are sure to remember. ",
        img: "/-/jssmedia/CFF18684E0BF49D0BEDE9396E8A9AE46.ashx",
        color: "color2",
        url: "/catering/heat-n-serve/sides",
      },

      {
        Heading: "Cracker Barrel Pancake Mix",
        description:
          "Welcome loved ones to the breakfast table with the aroma of buttermilk pancakes on the griddle. Our mix makes it easy.",
        img: "/-/jssmedia/41EB6BF53DDB4217980F31C8AA6B8C63.ashx",
        color: "color3",
        url: "/catering/grocery-n-games",
      },
    ];

    //////////////////////

    const lessthansixcoldlaterlunchdinner = [
      {
        Heading: "HOLIDAY HEAT N’ SERVE FAMILY DINNER",
        description:
          "Enjoy holiday traditions at home with our chilled to reheat Holiday Family Dinner, perfect for smaller gatherings.",
        img: "/-/jssmedia/9539828D4EF341FF88C97EBAB9ECFD85.ashx",
        color: "color1",
        url: "/catering/heat-n-serve/holiday-offerings",
      },

      {
        Heading: "Take n’ Bake Mac n’ Cheese",
        description:
          "A family favorite sure to please, pair with a variety of sides that come chilled and ready to heat.",
        img: "/-/jssmedia/BE558FD7E96941AB8C0AFEACB574BDBE.ashx",
        color: "color2",
        url: "/catering/heat-n-serve/sides",
      },

      {
        Heading: "New Three Cheese Squash Casserole",
        description:
          "Make meal prep easier  by adding our seasonal Three Cheese Squash Casserole to your next holiday meal.",
        img: "/-/jssmedia/E5533D1F9C2F4A7E8F8A5AA574FEDA7B.ashx",
        color: "color3",
        url: "/catering/heat-n-serve/sides",
      },

      {
        Heading: "Seasonal Southern Sides",
        description:
          "Our seasonal sides complete any holiday feast. Choose from our Three Cheese Squash Casserole and other traditional favorites, chilled to reheat later.",
        img: "/-/jssmedia/6077796B9D6C45C891B38FAD7A92F21D.ashx",
        color: "color4",
        url: "/catering/heat-n-serve/sides",
      },
    ];

    // individual

    const individualbreakfast = [
      {
        Heading: "New Grandma's Holiday Sampler",
        description:
          "Country Fried Turkey with gravy, two Pancakes with cranberry orange butter and syrup. Served with Hashbrown Casserole or Fried Apples and two eggs.",
        img: "/-/jssmedia/EAA39B27C82947DC96AAAF6D460BD0EA.ashx",
        color: "color1",
        url: "/menu/breakfast-all-day/pancakes-n-such",
      },

      {
        Heading: "Momma's French Toast Breakfast<sup>®</sup>",
        description:
          "Four slices of our Sourdough bread, grilled and topped with butter and 100% Pure Natural Syrup.",
        img: "/-/jssmedia/BA68F43CCEF249A88637F9978DCA1774.ashx",
        color: "color2",
        url: "/menu/breakfast-all-day/pancakes-n-such",
      },

      {
        Heading: "Old-Timer's Breakfast",
        description:
          "Two farm fresh eggs with your choice of Fried Apples or Hashbrown Casserole and Thick-Sliced Bacon or Sausage.",
        img: "/-/jssmedia/47E496C9878A46659CD0010CF3CEEC3D.ashx",
        color: "color3",
        url: "/menu/breakfast-all-day/Traditional-Favorites",
      },
    ];

    const individuallunchdinner = [
      {
        Heading: "Chicken n' Dumplins",
        description:
          "Chicken tenderloins and freshly prepared dumplins. Both slow simmered in our own chicken stock right in our kitchen.",
        img: "/-/jssmedia/23E657C009BC46CE8417CB69EAB3EDF9.ashx",
        color: "color2",
        url: "/menu/Dinner/Chicken-n-Turkey",
      },

      {
        Heading: "Meatloaf",
        description:
          "A thick slice of our special recipe Meatloaf made with tomatoes, onions, and green peppers.",
        img: "/-/jssmedia/9FB715D3901D4BA38D9098188680A6F4.ashx",
        color: "color1",
        url: "/menu/Dinner/Beef",
      },

      {
        Heading: "It's Back Country Fried Turkey",
        description:
          "Turkey fillets topped with gravy and served with our new Three Cheese Squash Casserole, one side, sample of cranberry relish, and biscuits or corn muffins.",
        img: "/-/jssmedia/94BFA9D96A294E828D54146A5E4ED798.ashx",
        color: "color3",
        url: "/menu/Dinner/Chicken-n-Turkey",
      },
    ];

    //  declare six variables, then map that variable into the iterator depending on the final state

    var data = individuallunchdinner;
    var menuItemsHeader = "Try these catering favorites";

    // more than six

    if (
      this.state.StepOneSelection == "morethansix" &&
      this.state.StepTwoSelection == "hotnow" &&
      this.state.StepThreeSelection == "breakfast"
    ) {
      data = morethansixhotnowbreakfast;
      menuItemsHeader = "Breakfast All Day To Feed A Group";
    } else if (
      this.state.StepOneSelection == "morethansix" &&
      this.state.StepTwoSelection == "hotnow" &&
      this.state.StepThreeSelection == "lunchdinner"
    ) {
      data = morethansixhotnowlunchdinner;
      menuItemsHeader = "Lunch n’ Dinner Meals To Feed A Group";
    } else if (
      this.state.StepOneSelection == "morethansix" &&
      this.state.StepTwoSelection == "coldlater" &&
      this.state.StepThreeSelection == "breakfast"
    ) {
      data = morethansixcoldlaterbreakfast;
      menuItemsHeader = "Heat n’ Serve Options To Feed A Group";
    } else if (
      this.state.StepOneSelection == "morethansix" &&
      this.state.StepTwoSelection == "coldlater" &&
      this.state.StepThreeSelection == "lunchdinner"
    ) {
      data = morethansixcoldlaterlunchdinner;
      menuItemsHeader = "Heat n’ Serve Options To Feed A Group";
    }

    // less than six
    else if (
      this.state.StepOneSelection == "lessthansix" &&
      this.state.StepTwoSelection == "hotnow" &&
      this.state.StepThreeSelection == "breakfast"
    ) {
      data = lessthansixhotnowbreakfast;
      menuItemsHeader = "Family Breakfast, Bundled or Build Your Own";
    } else if (
      this.state.StepOneSelection == "lessthansix" &&
      this.state.StepTwoSelection == "hotnow" &&
      this.state.StepThreeSelection == "lunchdinner"
    ) {
      data = lessthansixhotnowlunchdinner;
      menuItemsHeader = "Family Meal Baskets n’ Sides";
    } else if (
      this.state.StepOneSelection == "lessthansix" &&
      this.state.StepTwoSelection == "coldlater" &&
      this.state.StepThreeSelection == "breakfast"
    ) {
      data = lessthansixcoldlaterbreakfast;
      menuItemsHeader = "Take n’ Bake Breakfast Options";
    } else if (
      this.state.StepOneSelection == "lessthansix" &&
      this.state.StepTwoSelection == "coldlater" &&
      this.state.StepThreeSelection == "lunchdinner"
    ) {
      data = lessthansixcoldlaterlunchdinner;
      menuItemsHeader = "Take n’ Bake Family Meal Options";
    }

    // individual
    else if (
      this.state.StepOneSelection == "individual" &&
      // this.state.StepTwoSelection == "hotnow" || "coldlater" &&
      this.state.StepThreeSelection == "breakfast"
    ) {
      data = individualbreakfast;
      menuItemsHeader = "Breakfast Classics To Enjoy";
    } else if (
      this.state.StepOneSelection == "individual" &&
      // this.state.StepTwoSelection == "hotnow" || "coldlater" &&
      this.state.StepThreeSelection == "lunchdinner"
    ) {
      data = individuallunchdinner;
      menuItemsHeader = "Lunch n’ Dinner Homestyle Favorites";
    }

    // console.log(menuItemsHeader);

    //data = morethansixhotnowbreakfast;

    const showMenuItems = data.map((item) => {
      return {
        fields: {
          cardColor: {
            fields: {
              value: {
                value: item.color,
              },
            },
          },
          image: {
            value: {
              alt: item.Heading,
              src: item.img,
              title: item.description

            },
          },
          link1: {
            value: {
              class: "cb-button-primary",
              href: item.url,
              text: "Order Now",
              title: "Order Now",
            },
          },
          linkType: {
            value: "default",
          },
          subtitle: {
          },
          text: {
            value: item?.description || "",
          },
          title: {
            value: item.Heading,
            class: "cb-card__title",
          },
        }, //
      };
    });

    // console.log("Show Menu Items:");
    // console.log(showMenuItems);

    return (
      <div>
        <Container>
          <div
            className={wizardBox}
            style={{
              margin: "16px",
              textAlign: "left",
            }}
          >
            <Row>
              <Col lg="6" md="12" className="order-xl-1 order-3">
                <Heading
                  className="cb-type__title"
                  field={{
                    value: "Plan Your Holiday Menu",
                  }}
                  level={2}
                />

                <ContentBlock
                  alignment="left"
                  model={{
                    text: {
                      value:
                         "Just answer three simple questions, and we'll suggest some homestyle favorites for you.",
                    },
                  }}
                />

                <p className="cb-banner__text">
                  {this.state.firstSelectionText}
                </p>

                <p className="cb-banner__text">
                  {this.state.secondSelectionText}
                </p>

                <form class={hideOne}>
                  <div class="form-group">
                    <div class="cb-form__select-wrapper">
                      <select
                        value={this.state.StepOneSelection}
                        onChange={this.handleChange}
                        id="faqFilter-32"
                        class="cb-form__select cb-form__select-wrapper form-control form-control"
                      >
                        <option value="select">
                          How many people will you be feeding?
                        </option>
                        <option value="individual">Individual</option>
                        <option value="lessthansix">Fewer Than Six</option>
                        <option value="morethansix">More Than Six</option>
                      </select>
                    </div>
                  </div>
                </form>

                <form class={hideTwo}>
                  <div class="form-group">
                    <div class="cb-form__select-wrapper">
                      <select
                        value={this.state.StepTwoSelection}
                        onChange={this.handleChangeTwo}
                        id="stepTwoID"
                        class="cb-form__select form-control form-control"
                      >
                        <option className="wrap_select" value="select">
                          {" "}
                          Would you like a hot meal for now or a chilled meal for later?
                        </option>

                        <option value="hotnow">Hot Now</option>

                        <option value="coldlater">Cold for Later</option>
                      </select>
                    </div>
                  </div>
                </form>

                <form class={hideThree}>
                  <div class="form-group">
                    <div class="cb-form__select-wrapper">
                      <select
                        value={this.state.StepThreeSelection}
                        onChange={this.handleChangeThree}
                        id="faqFilter-32"
                        class="cb-form__select form-control form-control"
                      >
                        <option value="select">
                          Do you want breakfast or lunch/dinner?
                        </option>

                        <option value="breakfast">Breakfast</option>

                        <option value="lunchdinner">Lunch/Dinner</option>
                      </select>
                    </div>
                  </div>
                </form>
              </Col>

              {this.state.width < 992 && (
                <Col className="order-2" style={{ height: "16px" }} />
              )}
              <Col lg="6" md="12" className="order-xl-2 order-1">
                <div
                  class="highlight_image"
                  style={{
                    textAlign: "center",
                    borderRadius: "15px",
                    height: "320px",
                    width: "100%",
                    margin: "0",
                    backgroundImage: `url(/-/jssmedia/${highlightImage})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize : "cover"

                  }}
                ></div>
              </Col>
            </Row>
          </div>

          <br />

          <Row>
            <Col>
              <div
                class={finalChoices}
                style={{
                  margin: "0px",
                  // textAlign: 'center'
                }}
              >
                <Heading
                  className="cb-type__title"
                  field={{
                    value: menuItemsHeader,
                  }}
                  level={2}
                />

                <CardList
                  cardDisplayType="list"
                  cards={showMenuItems}
                  layout={{
                    background: "default",
                    padding: "medium",
                  }}
                  totalCardsInRow={data.length}
                />

                <div
                  style={{
                    margin: "0 auto",
                    textAlign: "center",
                  }}
                >
                  <Button
                    active={false}
                    className="cb-button-primary"
                    disabled={false}
                    onClick={this.resetButtons}
                    size=""
                    type="button"
                    variant="primary"
                  >
                    Start Over
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <br />
        <br />

        <Row></Row>
      </div>
    );
  }
}

export default HolidayWizard;
