import React from 'react';
import { connect } from 'react-redux';
import { Search as DesignSearch } from 'cb-design-system';
import { ducks } from "../../ducks";

const { search: searchDuck } = ducks;

const { operations } = searchDuck
const { pageChange,
  termChange,
  topicChange,
  categoriesChange,
  priceChange,
  setPageSize,
  setDataSource,
  sortByChange } = operations

const Search = (props) => {
  const {
    pageSize = {},
    pageButtons = {},
    resultsHeading = {},
    resultsCountLabel = {},
    sortByOptions = []
  } = props.fields;


  return (
    <DesignSearch
      {...props}
      pageSize={pageSize.value}
      pageButtons={pageButtons.value}
      resultsHeading={resultsHeading.value}
      resultsCountLabel={resultsCountLabel.value}
      sortByOptions={sortByOptions}/>
  );
};

const mapStateToProps = (state) => {
  return {
    total : state.search.totalSearch || 0,
    currentPage : state.search.currentPage || 0,
    query: state.search.searchTerm || "",
    results: state.search.searchResults || [],
    facets: state.search.facets || [],
    loading: state.search.isLoading
  };
}

const mapDispatchToProps = (dispatch) => ({
  pageChange: (newPage) => dispatch(pageChange(newPage)),
  termChange: (term) => dispatch(termChange(term)),
  topicChange: (topic, isAdd) => dispatch(topicChange(topic, isAdd)),
  categoriesChange: (categories, isAdd) => dispatch(categoriesChange(categories, isAdd)),
  priceChange: (price, isAdd) => dispatch(priceChange(price, isAdd)),
  pageSizeSet: (pageSize) => dispatch(setPageSize(pageSize)),
  setDataSource: (dataSource) => dispatch(setDataSource(dataSource)),
  sortByChange: (dataSource) => dispatch(sortByChange(dataSource))
}); 


export default connect(mapStateToProps, mapDispatchToProps)(Search);


