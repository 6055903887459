
import React from 'react';
import { Cart as DesignCart } from 'cb-design-system';
import { connect, ReactReduxContext } from 'react-redux';
import { ducks } from "../../ducks";

import { AzureADClient, authProviderClient } from '../../AuthComponent';

const {
  cart: cartDuck,
  menu: menuDuck,
  account: accountDuck
} = ducks;
const { operations } = cartDuck;

const { getCart: duckGetCart,
  deleteLine: duckDeleteLine,
  patchLine: duckPatchLine,
  saveDeliveryMode,
  setTimeWanted,
  saveForEdit,
  getDeliveryOptions,
  resetGoToCheckout = () => console.log("resetGoToCheckpoint"),
  postTip,
  postDeliveryMode,
  dumpCart,
  removeGiftCard,
  removeAllGiftCards,
  clearStates,
  setRetail,
  getShippingModes,
  setPaypalOrder,
  requestPaypalOrder,
  setShippingMode,
  handleRemoveTipAndEntitlements
} = operations;


const layout = {
  background: "default",
  padding: "medium",
}

const Cart = (props) => {
  const limitModel = {
    displayName: props.fields?.DisplayName?.value || 'Individually Packaged Meals',
    overrideMin: props.fields?.OverrideMin?.value || 8,
    overrideMax: props.fields?.OverrideMax?.value || 20,
    errorMessage: props.fields?.ErrorMessage?.value || 'Limit not Reached',
    RewardRedemption: props.fields?.RewardRedemption || null,
    UnauthenticatedModule: props.fields?.UnauthenticatedModule || null
  }

  let params = window.location.search;
  let decryptedparams = new URLSearchParams(params.toLowerCase());
  let retaincart = decryptedparams.get("retaincart");
  const autoLogin = retaincart ? true : false;
  return (
    <ReactReduxContext.Consumer>
      {((context) => {
        return (
          <AzureADClient  forceLogin={autoLogin} provider={authProviderClient} reduxStore={context.store}>
            {
              ({login, logout, authenticationState, error, accountInfo}) => {
                return(
                <DesignCart
                  store={context.store}
                  authenticationState={authenticationState}
                  accountInfo={accountInfo}
                  handleLogin={login}
                  limitModel={limitModel}

                  {...props}
                />)
              }
            }
          </AzureADClient>
        )}
      ).bind(this)
    }
    </ReactReduxContext.Consumer>
)}

const mapStateToProps = (state) => {

  let silverwareRoute = 'menu/silverware-n-extras';

  const shippingModes = state.cart.shippingModes || []

  return {
    fullCart : state.cart,
    cart : state.cart.cart,
    cost: state.cart.price,
    isLoading: state.cart.isLoading,
    quantity: state.cart.quantity,
    goToCheckout: state.cart.goToCheckout,
    deliveryMode: state.cart.deliveryMode,
    shippingMode: state.cart.shippingMode,
    deliveryModes: state.cart.deliveryModes,
    shippingModes: shippingModes.map(el => el.name),
    shippingCosts: shippingModes.map(el => (el.cost) ? `$${el.cost.toFixed(2)}` : "Free"),
    isCater: state.cart.isCatering,
    isFood: state.cart.isFood,
    isRetail: state.cart.isRetail,
    food: state.cart.food,
    cater: state.cart.cater,
    location: state.location.location,
    destructuredCart: state.cart.destructuredCart,
    silverwareRoute:silverwareRoute,
    registerLogin: state.account.registerLogin,
    leadtimeErr: state.cart.leadtimeErr,
    rewardsState: state.rewards
  };
}

const mapDispatchToProps = (dispatch) => ({
  getCart: (jwtIdToken) => dispatch(duckGetCart(jwtIdToken)),
  deleteItem: (id) => dispatch(duckDeleteLine(id)),
  patchItem: (lineId, options, newQuantity) => dispatch(duckPatchLine(lineId, options, newQuantity)),
  setTime: (timeObj) => dispatch(setTimeWanted(timeObj)),
  saveDeliveryMode: (travelType) => dispatch(saveDeliveryMode(travelType)),
  setShippingMode: (shippingMode) => dispatch(setShippingMode(shippingMode)),
  saveForEdit: (item, link, hash) => dispatch(saveForEdit(item, link, hash)),
  getDeliveryModes: () => dispatch(getDeliveryOptions()),
  resetGoToCheckout: (setting) => dispatch(resetGoToCheckout(setting)),
  clearTip: () => dispatch(postTip(0, 0)),
  resetToASAP: () => {
    console.log('Reset called')
    dispatch(postDeliveryMode({caterDeliveryMode: "pickup", foodDeliveryMode: "pickup"}));
  },
  setRetail: (addressInfo, deliveryMode) =>
    dispatch(setRetail(addressInfo, deliveryMode)),
  dumpCart: () => dispatch(dumpCart()),
  removeGiftCard: () => dispatch(removeGiftCard()),
  removeAllGiftCards: () => dispatch(removeAllGiftCards()),
  clearStates: () => dispatch(clearStates()),
  getShippingModes: () => dispatch(getShippingModes()),
  requestPaypalOrder: () => dispatch(requestPaypalOrder()),
  setPaypalOrder: (json) => dispatch(setPaypalOrder(json)),
  removeTipAndEntitlements: (data) => dispatch(handleRemoveTipAndEntitlements(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
