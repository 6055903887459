import React from 'react';
import { Wrapper } from 'cb-design-system';
import { Container, Row, Col } from 'react-bootstrap';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const TwoColumns = (props) => {
  const mainLayout = {
    background: "solid",
    topPadding: "medium",
    bottomPadding: "medium",
  }

  return (
    <Wrapper layout={mainLayout}>
      <Container>
        <Row>
          <Col md={4} xs={12}>
            <Placeholder name="column-1" rendering={props.rendering} />
          </Col>
          <Col md={8} xs={12}>
            <Placeholder name="column-2" rendering={props.rendering} />
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default TwoColumns;


