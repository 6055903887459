import React from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { LoyaltyLogIn as BsLoyaltyLogIn } from "cb-design-system";
import { authProviderClient } from "../../AuthComponent";
import { authConfig } from "../../AuthProvider";

const LoyaltyLogIn = (props) => {
  const model = props.fields;

  const handleNoUserFound = (email) => {
    const redirectUrl = model.redirectUrl.value.href || "";

    const currentUrl = new URL(window.location.href);
    const searchParams = currentUrl.searchParams;
    const queryRedirect = searchParams.get("redirect");

    if (queryRedirect) {
      window.localStorage.setItem("LoyaltyLogInRedirectUrl", queryRedirect);
    }

    (async () => {
      await authProviderClient.setAuthenticationParameters({
        authority: `${authConfig.domain}/${authConfig.mfaPolicy}`,
        loginHint: email,
        navigateToLoginRequestUrl: false,
        redirectUri: window.location.origin + redirectUrl,
        prompt: "login",
      });
      await authProviderClient.login();
    })();
  };

  return (
    <React.Fragment>
      <BsLoyaltyLogIn model={model} handleNoUserFound={handleNoUserFound} />
    </React.Fragment>
  );
};

export default withSitecoreContext()(LoyaltyLogIn);
